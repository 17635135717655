import { BrowserRouter, Routes, Route } from "react-router-dom";

// Public
import Terms from "./pages/Public/Terms";
import Policy from "./pages/Public/Policy";
import Landing from "./pages/Public/Landing";
import Receipt from "./pages/Public/Receipt";
import FallbackLanding from "./pages/Public/FallbackLanding";

// Admin
import Admin from "./pages/Admin/Admin";
import Account from "./pages/Admin/Account";

// Sudo
import Sudo from "./pages/Sudo/Sudo";
import Accounts from "./pages/Sudo/Accounts";
import EditProperty from "./pages/Sudo/EditProperty";
import CreateAccount from "./pages/Sudo/CreateAccount";
import AddProperty from "./pages/Sudo/AddProperty/AddProperty";

// Utils
import "./App.css";
import ProtectedRoute from "./pages/_AppTemplate/ProtectedRoute";
import History from "./pages/Admin/History";
import LandingWrapper from "./pages/Landing/LandingWrapper";
import LoginWrapper from "./pages/Public/LoginWrapper";
import ReceiptIndexes from "./pages/Admin/ReceiptIndexes";
import Reservations from "./pages/Admin/Reservations";
import Docs from "./pages/Public/Docs";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* PUBLIC */}
        <Route index element={<LandingWrapper />} />
        <Route path="/rezerva" element={<Landing />} />

        <Route path="login" element={<LoginWrapper />} />
        <Route path="/receipt" element={<Receipt />} />
        <Route path="/termeni" element={<Terms />} />
        <Route path="/politica" element={<Policy />} />
        <Route
          path="/eroare-plata"
          element={
            <FallbackLanding title="Oooops!" header="Se pare ca a fost o problemă cu plata ta." />
          }
        />

        {/* ADMIN */}
        <Route
          path="admin"
          element={<ProtectedRoute availableTo="admin" page="admin" Target={Admin} />}
        />
        <Route
          path="reservations"
          element={<ProtectedRoute availableTo="admin" page="reservations" Target={Reservations} />}
        />
        <Route
          path="account"
          element={<ProtectedRoute availableTo="admin" page="account" Target={Account} />}
        />

        <Route
          path="history"
          element={<ProtectedRoute availableTo="admin" page="history" Target={History} />}
        />
        <Route
          path="indexes"
          element={<ProtectedRoute availableTo="admin" page="indexes" Target={ReceiptIndexes} />}
        />
        <Route
          path="/docs"
          element={<ProtectedRoute availableTo="admin" page="docs" Target={Docs} />}
        />

        {/* SUDO */}
        <Route
          path="sudo"
          element={<ProtectedRoute availableTo="sudo" page="sudo" Target={Sudo} />}
        />
        <Route
          path="add-property"
          element={<ProtectedRoute availableTo="sudo" page="add-property" Target={AddProperty} />}
        />
        <Route
          path="edit-property"
          element={<ProtectedRoute availableTo="sudo" page="edit-property" Target={EditProperty} />}
        />
        <Route
          path="create-account"
          element={
            <ProtectedRoute availableTo="sudo" page="create-account" Target={CreateAccount} />
          }
        />
        <Route
          path="accounts"
          element={<ProtectedRoute availableTo="sudo" page="accounts" Target={Accounts} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

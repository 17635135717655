import React, { useState } from "react";
import "./TopBarDash.css";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";

const searchResults = [
  { label: "Cum modific o rezervare ?" },
  { label: "Care este diferenta intre o rezervare online si una fizica ?" },
  { label: "Cum modific o rezervare fizica?" },
  { label: "Cum generez o stornare?" },
];

export default function TopBarDash({ currentPageName, user, logoutHandler }) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleCloseConfirm = () => setOpenConfirm(false);
  const handleOpenConfirm = () => setOpenConfirm(true);

  return (
    <>
      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ești sigur ca dorești să te deloghezi?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseConfirm}>Renunță</Button>
          <Button
            onClick={async () => {
              logoutHandler();
            }}
            autoFocus
          >
            Delogare
          </Button>
        </DialogActions>
      </Dialog>

      <div className="top-dash-container">
        <div className="top-dash-container-left">
          <p>{currentPageName}</p>
        </div>
        <div className="top-dash-container-right">
          {/* FUTURE FEATURE - DON'T DELETE  */}
          {/* <div className="top-dash-container-right-search">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={searchResults}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Caută întrebări..." />}
          />
        </div> */}
          <div className="top-dash-container-right-btns">
            <div className="top-dash-container-right-btns-section">
              <img src="/topBarDash/user.png"></img>
              <div class="top-dash-container-right-btns-section-text">
                <p>{user.name}</p>
              </div>
            </div>
            <div className="top-dash-container-right-btns-section">
              <img src="/topBarDash/home.png"></img>
              <div class="top-dash-container-right-btns-section-text">
                <p>Coltii Morarului</p>
              </div>
            </div>
            <div className="top-dash-container-right-btns-section">
              <button onClick={handleOpenConfirm}>Delogare</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

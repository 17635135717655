import React, { useState, useCallback, useEffect } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

export default function LighBoxScroller({ images }) {
  const [localParsedImages, setLocalParsedImages] = useState([]);

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  useEffect(() => {
    attachAspectToImages(images);
  }, [images]);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function determinaAspectImagine(linkImagine) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.src = linkImagine;
      img.onload = function () {
        var aspectRatio = this.width / this.height;
        var rezultat = {};
        if (aspectRatio > 1) {
          rezultat.width = 4; // landscape
          rezultat.height = 3;
        } else if (aspectRatio < 1) {
          rezultat.width = 3; // portrait
          rezultat.height = 4;
        } else {
          rezultat.width = 1; // square
          rezultat.height = 1;
        }
        resolve(rezultat);
      };
      img.onerror = function () {
        reject(new Error("A apărut o eroare la încărcarea imaginii."));
      };
    });
  }

  async function attachAspectToImages(images) {
    let parsedImages = [];
    for (let i = 0; i < images.length; i++) {
      let currentImgAspect = await determinaAspectImagine(images[i]);
      parsedImages.push({
        src: images[i],
        key: i.toString(),
        ...currentImgAspect,
      });
    }
    setLocalParsedImages(parsedImages);
  }

  return (
    <div>
      <Gallery photos={localParsedImages} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel currentIndex={currentImage} views={localParsedImages} />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

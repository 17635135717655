import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import "./RoomTabItem.css";

export default function RoomTabItem({
  roomItem,
  index,
  facilities,
  setFacilities,
  roomComments,
  setRoomComments,
  modalMode,
  currentReservation,
}) {
  const handleCheckBoxAction = (event, checkBoxIndex) => {
    setFacilities((prev) => {
      let currentCheckBox = prev[index]["checkboxFacilities"][checkBoxIndex];
      currentCheckBox["selected"] = !currentCheckBox["selected"];
      let updatedState = [...prev];
      updatedState[index]["checkboxFacilities"][checkBoxIndex] = currentCheckBox;
      return updatedState;
    });
  };

  const handleIntervalAction = (add, intervalIndex) => {
    //add - boolean
    let currentIntervalFacility = facilities[index]["intervalFacilities"][intervalIndex];
    console.log("currentIntervalFacility:", currentIntervalFacility);

    let currentValue = currentIntervalFacility["selectedValue"];
    let nextValue = undefined;
    if (add == true) {
      nextValue = currentValue + 1;
    } else {
      nextValue = currentValue - 1;
    }
    if (nextValue > currentIntervalFacility["maxValue"]) {
      window.alert(`Ai atins deja limita de ${currentIntervalFacility["maxValue"]}!`);
      return;
    }
    if (nextValue < 0) {
      window.alert("Aceasta facilitate este 0 deja, nu poti merge mai jos de atat!");
      return;
    }
    setFacilities((prev) => {
      let currentInterval = prev[index]["intervalFacilities"][intervalIndex];
      currentInterval["selectedValue"] = nextValue;
      let updatedState = [...prev];
      updatedState[index]["intervalFacilities"][intervalIndex] = currentInterval;
      return updatedState;
    });
  };

  const handleRoomCommentsUpdate = (newValue) => {
    setRoomComments((prev) => {
      let copy = [...prev];
      copy[index]["value"] = newValue;
      return copy;
    });
  };

  return (
    <div className="reservations-container-modal-tabitem">
      <div className="reservations-container-modal-tabitem-facilities">
        <h4>Modifică facilitățile:</h4>
        <div className="reservations-container-modal-tabitem-facilities-checkbox">
          {facilities === undefined
            ? null
            : facilities[index].checkboxFacilities.map((el, checkBoxIndex) => {
                return (
                  <div className="reservations-container-modal-tabitem-facilities-checkbox-item">
                    <p>
                      {el.name}:{" "}
                      <Checkbox
                        checked={el.selected}
                        onChange={(event) => {
                          if (el.selected == false && modalMode === 1) {
                            window.alert(
                              "Nu poti adauga o facilitate in modul de ediatare 'eliminta elemente'"
                            );
                            return;
                          }
                          if (el.selected == true && modalMode === 2) {
                            window.alert(
                              "Nu poti elimina o facilitate in modul de ediatare 'adauga elemente'"
                            );
                            return;
                          }

                          handleCheckBoxAction(event, checkBoxIndex);
                        }}
                      />
                      <b>{el.price} RON</b>
                      {` (Initial: ${
                        currentReservation["checkout"][index]["checkboxFacilities"][checkBoxIndex][
                          "selected"
                        ] === false
                          ? "Debifat"
                          : "Bifat"
                      })`}
                      <button
                        onClick={() => {
                          if (
                            el.selected !==
                            currentReservation["checkout"][index]["checkboxFacilities"][
                              checkBoxIndex
                            ]["selected"]
                          ) {
                            handleCheckBoxAction(null, checkBoxIndex);
                          }
                        }}
                      >
                        Reseteaza
                      </button>
                    </p>
                    <span></span>
                  </div>
                );
              })}
        </div>
        <div className="reservations-container-modal-tabitem-facilities-interval">
          {facilities === undefined
            ? null
            : facilities[index].intervalFacilities.map((el, intervalIndex) => {
                return (
                  <div className="reservations-container-modal-tabitem-facilities-interval-item">
                    <p>{el.name}: </p>
                    <p>
                      {modalMode === 2 ? null : (
                        <span
                          onClick={() => {
                            handleIntervalAction(false, intervalIndex);
                          }}
                        >
                          -
                        </span>
                      )}
                      {el.selectedValue}
                      {modalMode === 1 ? null : (
                        <span
                          onClick={() => {
                            handleIntervalAction(true, intervalIndex);
                          }}
                        >
                          +
                        </span>
                      )}
                      &nbsp;
                      <b>[{el.price} RON]</b>
                      <p>
                        <div style={{marginLeft: '10px'}}>
                          {` Initial: ${currentReservation["checkout"][index]["intervalFacilities"][intervalIndex]["selectedValue"]} `}
                        </div>
                        <button
                          onClick={() => {
                            if (
                              el.selectedValue !==
                              currentReservation["checkout"][index]["intervalFacilities"][
                                intervalIndex
                              ]["selectedValue"]
                            ) {
                              setFacilities((prev) => {
                                let currentInterval =
                                  prev[index]["intervalFacilities"][intervalIndex];
                                currentInterval["selectedValue"] =
                                  currentReservation["checkout"][index]["intervalFacilities"][
                                    intervalIndex
                                  ]["selectedValue"];
                                let updatedState = [...prev];
                                updatedState[index]["intervalFacilities"][intervalIndex] =
                                  currentInterval;
                                return updatedState;
                              });
                            }
                          }}
                        >
                          Reseteaza
                        </button>
                      </p>
                    </p>
                  </div>
                );
              })}
        </div>
      </div>
      <div className="reservations-container-modal-tabitem-comm">
        <h4>Adaugă un comentariu: (doar tu poți îl poți vedea)</h4>
        <textarea
          value={roomComments ? roomComments[index]["value"] : ""}
          onChange={(e) => {
            handleRoomCommentsUpdate(e.target.value);
          }}
          placeholder="Adauga comentariul tau aici pentru aceasta camera..."
        ></textarea>
      </div>
    </div>
  );
}

import './DateRangeSelectorAdmin.css';
import DatePicker from "react-datepicker";

export default function DateRangeSelectorAdmin({localDateRange, setLocalDateRange, btnAction})
{
    let tommorow = new Date(new Date().getTime() + 86400 * 1000); //7 days
    
    const selectStartDate = (d) => {
        if (localDateRange[1] && d > localDateRange[1]) {
          window.alert("Data de check-in trebuie să fie înainte de data de check-out");
          setLocalDateRange((prev) => {
            let copy = [prev[1], prev[1]];
            return copy;
          });
        } else {
          setLocalDateRange((prev) => {
            let copy = [...prev];
            copy[0] = d;
            return copy;
          });
        }
    };

    const selectEndRange = (d) => {
        if (localDateRange[0] && d < localDateRange[0]) {
          window.alert("Data de check-out trebuie sa fie după data de check-in");
          setLocalDateRange((prev) => {
            let copy = [prev[0], prev[0]];
            return copy;
          });
        } else {
          setLocalDateRange((prev) => {
            let copy = [...prev];
            copy[1] = d;
            return copy;
          });
        }
    };

    return (
        <div className='date-range-selector-admin'>
            <div className='date-range-selector-admin-section'>
                <p>Start:</p>
                <DatePicker
                    selectsStart
                    selected={localDateRange[0]}
                    startDate={localDateRange[0]}
                    endDate={localDateRange[1]}
                    onChange={selectStartDate}
                />
            </div>
            <div className='date-range-selector-admin-section'>
                <p>End:</p>
                <DatePicker
                    selectsEnd
                    selected={localDateRange[1]}
                    startDate={localDateRange[0]}
                    endDate={localDateRange[1]}
                    onChange={selectEndRange}
                />
            </div>
            <div className='date-range-selector-admin-btn'>
                <button onClick={btnAction}>Refresh</button>
            </div>
        </div>
    )
}
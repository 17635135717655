import React, { useEffect } from "react";
import "./Style/HotelDetails.css";
import HotelDetailsTitle from "../../atoms/HotelDetailsTitle/HotelDetailsTitle";
import LighBoxScroller from "../../atoms/LighBoxScroller/LighBoxScroller";
import { Link } from "react-router-dom";

export default function HotelDetails({ hotelData }) {
  const extractGeneralFacilties = (roomSrc) => {
    let facilities = [];
    roomSrc.forEach((el) => {
      facilities = [...facilities, ...el.facilities];
    });

    let unqiues = new Set(facilities);

    return [...unqiues];
  };

  const extractContact = (hotelDataSrc) => {
    let phone = hotelDataSrc.details.phone;
    let address = hotelDataSrc.details.address;

    let email = hotelDataSrc.setup.email;

    return [
      ["/icons/phone.png", phone],
      ["/icons/map.png", address],
      ["/icons/email.png", email],
    ];
  };

  const extractSocial = (hotelDataSrc) => {
    let facebook = hotelDataSrc.details.facebookUrl;
    let instagram = hotelDataSrc.details.instagramUrl;

    return [
      ["/icons/facebook.png", "Facebook", facebook],
      // ["/icons/youtube.png", "YouTube", youtube],
      ["/icons/instagram.png", "Instagram", instagram],
    ];
  };

  const extractCompany = (hotelDataSrc) => {
    let name = "Firmă: Amamontis S.R.L";
    let address = "Adresă: Predeal, Strada Campina nr.12";
    let cui = "CUI: 3123234223423";

    return [name, address, cui];
  };

  return (
    <>
      {hotelData == undefined ? (
        <p>Loading</p>
      ) : (
        <div id="landing-hotel-details" className="hoteldetails-container">
          <div className="hoteldetails-container-section-top">
            <div className="hoteldetails-container-section-top-left">
              <div className="hoteldetails-container-section-top-left-header">
                <img src={hotelData.landingCss.logoUrl} />
                <p>{hotelData.details.name}</p>
              </div>

              <div className="hoteldetails-container-section-top-left-desc">
                <HotelDetailsTitle title="Despre locatie" />
                <p className="hoteldetails-container-section-top-left-desc-core">
                  {hotelData.details.description}
                </p>
              </div>

              <div className="hoteldetails-container-section-top-left-fac">
                <HotelDetailsTitle title="Dotari generale" />
                <div className="hoteldetails-container-section-top-left-fac-items">
                  {extractGeneralFacilties(hotelData.rooms).map((el, index) => {
                    return (
                      <div
                        key={index}
                        className="hoteldetails-container-section-top-left-fac-items-el"
                      >
                        <img src="/icons/facility.png" />
                        <span>{el}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="hoteldetails-container-section-top-right">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d86864.6475835498!2d27.626597304980482!3d47.12807300433676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sro!2sro!4v1703840745037!5m2!1sro!2sro"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          <div className="hoteldetails-container-section-gallery">
            <HotelDetailsTitle title="Galerie" />
            <div className="hoteldetails-container-section-gallery-core">
              <LighBoxScroller images={hotelData.photos} />
            </div>
          </div>

          <div className="hoteldetails-container-section-contact">
            <HotelDetailsTitle title="Contact" />
            <div className="hoteldetails-container-section-contact-core">
              <div className="hoteldetails-container-section-contact-core-column">
                {extractContact(hotelData).map((el, index) => {
                  return (
                    <div
                      key={index}
                      className="hoteldetails-container-section-contact-core-column-row"
                    >
                      <div className="hoteldetails-container-section-contact-core-column-row-img">
                        <img src={el[0]} />
                      </div>
                      <p>{el[1]}</p>
                    </div>
                  );
                })}
              </div>
              <div className="hoteldetails-container-section-contact-core-column">
                {extractSocial(hotelData).map((el, index) => {
                  return (
                    <div
                      key={index}
                      className="hoteldetails-container-section-contact-core-column-row"
                    >
                      <Link to={el[2]} target="_blank">
                        <div className="hoteldetails-container-section-contact-core-column-row-img">
                          <img src={el[0]} />
                        </div>
                        <p>{el[1]}</p>
                      </Link>
                    </div>
                  );
                })}
              </div>
              <div className="hoteldetails-container-section-contact-core-column">
                {extractCompany(hotelData).map((el, index) => {
                  return (
                    <div
                      key={index}
                      className="hoteldetails-container-section-contact-core-column-row-company"
                    >
                      <p>{el}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React from "react";
import "./Style/FallbackLanding.css";
import { Link } from "react-router-dom";

export default function FallbackLanding({ title, header }) {
  return (
    <div className="fallback-page-container">
      <div className="fallback-page-container-title">
        <p>{title}</p>
      </div>
      <div className="fallback-page-container-header">
        <p>{header}</p>
        <p></p>
      </div>
      <div className="fallback-page-container-action">
        <Link to="/login">Mergi la pagina principala</Link>
      </div>
    </div>
  );
}

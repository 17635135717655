import { useState, useEffect } from "react";
import axios from "axios";
import "./Style/Dashboard.css";
import { SERVER_ADDR } from "../../config";
import AdminOverview from "../../components/Dashboard/AdminOverview";
import WidgetAboutMagnus from "../../components/Dashboard/WidgetAboutMagnus";
import WidgetGraph from "../../components/Dashboard/WidgetGraph";
import { Link } from "react-router-dom";
import { formatDateToYYYYMMDDString } from "../../utils/functions";

export default function Admin({ user }) {
  const [reservationsRegions, setReservationsRegions] = useState(null);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    if (reservationsRegions !== null) {
      const extractedStats = {
        current: {
          revenue:
            reservationsRegions["current"].length > 0
              ? extractRevenueFromMonth(reservationsRegions["current"])
              : 0,
          counter:
            reservationsRegions["current"].length > 0
              ? extractReservationCountFromMonth(reservationsRegions["current"])
              : 0,
        },
        future: {
          revenue:
            reservationsRegions["future"].length > 0
              ? extractRevenueFromMonth(reservationsRegions["future"])
              : 0,
          counter:
            reservationsRegions["future"].length > 0
              ? extractReservationCountFromMonth(reservationsRegions["future"])
              : 0,
        },
      };
      extractedStats["future"]["gainRevenue"] = calculatePercentageDifference(
        extractedStats["current"]["revenue"],
        extractedStats["future"]["revenue"]
      );
      extractedStats["future"]["gainCounter"] = calculatePercentageDifference(
        extractedStats["current"]["counter"],
        extractedStats["future"]["counter"]
      );

      setStats(extractedStats);
    }
  }, [reservationsRegions]);

  function initDates(offset1, offset2) {
    const currentDate = new Date();

    const date1 = new Date();
    date1.setDate(currentDate.getDate() + offset1);

    const date2 = new Date();
    date2.setDate(currentDate.getDate() + offset2);

    return [date1, date2];
  }

  useEffect(() => {
    let hotelId = user["managedHotel"];

    const fetchData = async () => {
      const currentMonthDates = initDates(0, 30);
      const currentMonthFormatted = [
        formatDateToYYYYMMDDString(currentMonthDates[0]),
        formatDateToYYYYMMDDString(currentMonthDates[1]),
      ];

      const futureMonthDates = initDates(30, 60);
      const futureMonthFormatted = [
        formatDateToYYYYMMDDString(futureMonthDates[0]),
        formatDateToYYYYMMDDString(futureMonthDates[1]),
      ];

      const currentMonthItems = await fetchReservations(hotelId, currentMonthFormatted);
      const futureMonthItems = await fetchReservations(hotelId, futureMonthFormatted);

      setReservationsRegions({
        current: currentMonthItems,
        future: futureMonthItems,
      });
    };
    fetchData();
  }, [user]);

  const fetchReservations = async (hotelIdParam, range) => {
    try {
      let reservationsResp = await axios.get(
        `${SERVER_ADDR}/api/reservation?hotelId=${hotelIdParam}&startDate=${range[0]}&endDate=${range[1]}`
      );
      return reservationsResp.data;
    } catch (err) {
      console.log("Can't fetch reservations:", err);
      window.alert("Nu pot prelua rezervari!");
    }
  };

  const extractRevenueFromMonth = (reservations) => {
    let value = 0;
    reservations.forEach((reservationEl) => {
      let currentReservationValue = 0;
      reservationEl["roomsPricesPerDay"].forEach((room) => {
        Object.keys(room["currentRoomPrices"]).forEach((day) => {
          currentReservationValue += Number(room["currentRoomPrices"][day]["rate"]);
        });
      });
      value += currentReservationValue;
    });
    return value;
  };

  const extractReservationCountFromMonth = (reservations) => {
    return reservations.length;
  };

  function calculatePercentageDifference(num1, num2) {
    let difference = num2 - num1;
    let percentage = (difference / num1) * 100;
    return percentage;
  }

  return (
    <div className="admin-container">
      <div className="admin-container-my-page">
        <Link
          target="_blank"
          to={`/rezerva?hotel=${user["managedHotel"]}`}
          className="admin-container-my-page-core"
        >
          <img src="/adminDash/landing-page.png" />
          <p>Pagină rezervări</p>
        </Link>
      </div>
      <AdminOverview stats={stats} />
      <div style={{ display: "flex", marginTop: "20px" }}>
        <WidgetAboutMagnus />
        <WidgetGraph stats={stats} />
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Style/DateRangeSelector.css";
import Button from "../../atoms/Button/Button";
import {
  extractDateRangeNumber,
  formatDateToYYYYMMDDString,
  setQueryParam,
} from "../../utils/functions";

export default function DateRangeSelector({ dateRange, setDateRange, handleButtonSearchRooms }) {
  const [localDateRange, setLocalDateRange] = useState([null, null]);
  const [saveEnabled, setSaveEnabled] = useState(true);

  useEffect(() => {
    if (typeof dateRange[0] === "object" && typeof dateRange[1] === "object") {
      setLocalDateRange([dateRange[0], dateRange[1]]);
    }
  }, [dateRange]);

  useEffect(() => {
    if (dateRange[0] !== null && localDateRange[0] !== null && localDateRange[1] !== null) {
      if (
        dateRange[0].getTime() !== localDateRange[0].getTime() ||
        dateRange[1].getTime() !== localDateRange[1].getTime()
      ) {
        setSaveEnabled(false);
      } else {
        setSaveEnabled(true);
      }
    }
  }, [dateRange, localDateRange]);

  const selectStartDate = (d) => {
    if (localDateRange[1] && d > localDateRange[1]) {
      window.alert("Data de check-in trebuie să fie înainte de data de check-out");
      setQueryParam("startDate", formatDateToYYYYMMDDString(localDateRange[1]));
      setLocalDateRange((prev) => {
        let copy = [prev[1], prev[1]];
        return copy;
      });
    } else {
      setQueryParam("startDate", formatDateToYYYYMMDDString(d));
      setLocalDateRange((prev) => {
        let copy = [...prev];
        copy[0] = d;
        return copy;
      });
    }
  };
  const selectEndRange = (d) => {
    if (localDateRange[0] && d < localDateRange[0]) {
      window.alert("Data de check-out trebuie sa fie după data de check-in");
      setQueryParam("endDate", formatDateToYYYYMMDDString(localDateRange[0]));

      setLocalDateRange((prev) => {
        let copy = [prev[0], prev[0]];
        return copy;
      });
    } else {
      setQueryParam("endDate", formatDateToYYYYMMDDString(d));
      setLocalDateRange((prev) => {
        let copy = [...prev];
        copy[1] = d;
        return copy;
      });
    }
  };

  const handleButtonSearchRoomsProxy = () => {
    setSaveEnabled(true);
    setDateRange(localDateRange);
    handleButtonSearchRooms(localDateRange);
  };
  let tommorow = new Date(new Date().getTime() + 86400 * 1000);

  return (
    <div className="daterange-selector-container">
      <div className="daterange-selector-container-col">
        <p>Check in:</p>
        <DatePicker
          selectsStart
          selected={localDateRange[0]}
          minDate={tommorow}
          startDate={localDateRange[0]}
          endDate={localDateRange[1]}
          onChange={selectStartDate}
        />
      </div>
      <img
        src="/icons/right-arrow.png"
        className="daterange-selector-container-col-arrow"
        alt="right-icon"
      />
      <div className="daterange-selector-container-col" >
        <p>Check out:</p>
        <DatePicker
          selectsEnd
          selected={localDateRange[1]}
          minDate={tommorow}
          startDate={localDateRange[0]}
          endDate={localDateRange[1]}
          onChange={selectEndRange}
        />
      </div>
      <div className="daterange-selector-container-action">
        <div className="daterange-selector-container-action-range">
          <img src="/icons/moon.png" alt="night" />
          <p>
            {extractDateRangeNumber(dateRange) === 1
              ? "O noapte"
              : `${extractDateRangeNumber(dateRange)} nopți`}
          </p>
        </div>
        <div className="daterange-selector-container-action-action">
          <Button
            text="Vezi camere"
            onClick={handleButtonSearchRoomsProxy}
            disabled={saveEnabled}
          />
          <>
            {saveEnabled === false ? (
              <div className="daterange-selector-container-action-action-warning">
                <img src="/icons/danger.png" alt="danger" />
                <p>Apasă aici pentru a vedea noile camere disonibile!</p>
              </div>
            ) : null}
          </>
        </div>
      </div>
    </div>
  );
}

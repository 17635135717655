import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { CountrySelect, PhoneCode } from "./PhoneFields";
import TextField from "@mui/material/TextField";
import axios from "axios";
import "./Style/CheckoutModal.css";
import { extractPriceFromCheckout, formatDateToYYYYMMDDString } from "../../utils/functions";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Cookies from "js-cookie";
import { SERVER_ADDR } from "../../config";

export default function CheckoutModal({
  exitHandle,
  checkout,
  hotelData,
  hotelId,
  dateRange,
  rates,
  roomsPricesPerDay,
}) {
  // Stepper logic
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = ["Datele date", "Verifica comanda", "Alege metoda plata"];
  const [totalSum, setTotalSum] = useState(0);

  // Field values
  const [leftFields, setLeftFields] = useState({
    Nume: ["Damian", "text"],
    Prenume: ["Gabriel", "text"],
    Localitate: ["Vorona Botosani", "text"],
    Adresă: ["Strada Cristescu", "text"],
    "Adresă de e-mail": ["1231dw22f2dcw@gmail.com", "text"],
  });
  const [rightFields, setRightFields] = useState({
    Observații: ["Observatii example", "text"],
    "Numar telefon": ["0757336274", "number"],
  });
  const [country, setCountry] = useState({
    code: "AD",
    label: "Andorra",
    phone: "376",
  });
  const [phoneCode, setPhoneCode] = useState({
    code: "AD",
    label: "Andorra",
    phone: "376",
  });

  const [paymentMethod, setPaymentMethod] = useState("online");
  const [user, setUser] = useState(undefined);

  const checkToken = async () => {
    const myToken = Cookies.get("jwt");
    if (myToken == undefined) setUser(null);

    try {
      const { data } = await axios.get(`${SERVER_ADDR}/api/token`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
        },
      });

      setUser(data.user);
    } catch (err) {
      console.log("err", err);
      setUser(null);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  // Loading

  const handleNext = () => {
    let newSkipped = skipped;

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const decideCurrentStepComponent = (index) => {
    return stepsAsPages[index];
  };

  const handleFinalStep = async () => {
    const userFields = {
      ...leftFields,
      ...rightFields,
      country: country,
      phoneCode: phoneCode,
    };

    const magicPayload = {
      userFields: userFields,
      checkout: checkout,
      roomsPricesPerDay,
      dateRange: {
        startDate: formatDateToYYYYMMDDString(dateRange[0]),
        endDate: formatDateToYYYYMMDDString(dateRange[1]),
      },
      rates: rates,
    };

    const myToken = Cookies.get("jwt");

    if (paymentMethod === "fizic") {
      magicPayload["paymentMethod"] = "fizic";
      magicPayload["onSitePaymentAdminPass"] = {
        userThatTrigered: user,
        desiredHotelId: hotelId,
        token: myToken,
      };
    } else {
      magicPayload["paymentMethod"] = "online";
      magicPayload["onSitePaymentAdminPass"] = null;
    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const resp = await axios.post(
        `${SERVER_ADDR}/api/property/${hotelId}/transaction`,
        { ...magicPayload },
        config
      );

      if (resp.data.paymentMethod == "fizic") {
        window.alert("Plata a fost efectuată cu succes! \n Veti fi trimis la factura rezervarii.");
        window.location.href = `/receipt?id=${resp.data.reservationId}`;
      } else if (resp.data.paymentMethod == "online") {
        window.location.href = resp.data.stripeLink;
      } else {
        window.alert("Status plata, necunoscut!");
      }
    } catch (err) {
      if (err.response) {
        window.alert(err.response.data.error);
      }
      console.log("Can't create payment session:", err);
    }
  };

  const stepsAsPages = [
    <PageInputDetails
      leftFields={leftFields}
      setLeftFields={setLeftFields}
      rightFields={rightFields}
      setRightFields={setRightFields}
      country={country}
      setCountry={setCountry}
      phoneCode={phoneCode}
      setPhoneCode={setPhoneCode}
    />,
    <CheckChekoutDetails
      checkout={checkout} //items from the checkout
      leftFields={leftFields}
      rightFields={rightFields}
      country={country}
      phoneCode={phoneCode}
      dateRange={dateRange}
      rates={rates}
      totalSum={totalSum}
      setTotalSum={setTotalSum}
    />,
    <PageChoosePayment
      totalSum={totalSum}
      handleFinalStep={handleFinalStep}
      hotelId={hotelId}
      paymentMethod={paymentMethod}
      setPaymentMethod={setPaymentMethod}
      user={user}
    />,
  ];

  return (
    <div className="checkout-container-modal">
      <div className="checkout-container-modal-exit">
        <img src="/icons/exit.png" title="Inchide" onClick={exitHandle} />
      </div>
      <div className="checkout-container-modal-content">
        <Box sx={{ width: "100%", height: "100%" }}>
          <Stepper activeStep={activeStep} sx={{ height: "10%" }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div className="checkout-container-modal-content-pages">
            {decideCurrentStepComponent(activeStep)}
          </div>
          <div className="checkout-container-modal-content-actions">
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Inapoi
            </Button>
            <Button onClick={activeStep === steps.length - 1 ? handleFinalStep : handleNext}>
              {activeStep === steps.length - 1 ? "Plateste" : "Urmatorul"}
            </Button>
          </div>
        </Box>
      </div>
    </div>
  );
}

// Page 1
const PageInputDetails = ({
  leftFields,
  setLeftFields,
  rightFields,
  setRightFields,
  country,
  setCountry,
  phoneCode,
  setPhoneCode,
}) => {
  const updateGenericFields = (leftOrRight, who, newValue) => {
    if (leftOrRight == "left") {
      setLeftFields((prev) => {
        let copy = { ...prev };
        let arrCopy = [...copy[who]];
        copy[who] = [newValue, arrCopy[1]]; //updateNew value and keep field type (text or number)
        return copy;
      });
    } else if ((leftOrRight = "right")) {
      setRightFields((prev) => {
        let copy = { ...prev };
        let arrCopy = [...copy[who]];
        copy[who] = [newValue, arrCopy[1]]; //updateNew value and keep field type (text or number)
        return copy;
      });
    }
  };

  return (
    <div className="checkout-container-modal-inputs">
      <div className="checkout-container-modal-inputs-col">
        {Object.keys(leftFields).map((fieldName, index) => {
          return (
            <div key={index} className="modal-modal-next-page-el">
              <TextField
                sx={{ width: "100%" }}
                id="outlined-basic"
                label={fieldName}
                variant="outlined"
                type={leftFields[fieldName][1]}
                value={leftFields[fieldName][0]}
                onChange={(e) => {
                  updateGenericFields("left", fieldName, e.target.value);
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="checkout-container-modal-inputs-col">
        {Object.keys(rightFields).map((fieldName, index) => {
          return (
            <div key={index} className="modal-modal-next-page-el">
              <TextField
                sx={{ width: "100%" }}
                id="outlined-basic"
                label={fieldName}
                variant="outlined"
                type={rightFields[fieldName][1]}
                value={rightFields[fieldName][0]}
                onChange={(e) => {
                  updateGenericFields("right", fieldName, e.target.value);
                }}
              />
            </div>
          );
        })}
        <div className="modal-modal-next-page-el">
          <CountrySelect countryValue={country} setCountryValue={setCountry} />
        </div>
        <div className="modal-modal-next-page-el">
          <PhoneCode phoneCode={phoneCode} setPhoneCode={setPhoneCode} />
        </div>
      </div>
    </div>
  );
};

// Page 2
const CheckChekoutDetails = ({
  checkout,
  leftFields,
  rightFields,
  country,
  phoneCode,
  dateRange,
  rates,
  totalSum,
  setTotalSum,
}) => {
  const [extractedPrices, setExtractedPrices] = useState([]);
  const initPrices = (checkoutSource, ratesSource, dateRangeSource) => {
    let extractedPrices = extractPriceFromCheckout(checkoutSource, ratesSource, dateRangeSource);
    setTotalSum(extractedPrices["total"]);
    setExtractedPrices(extractedPrices["collectItems"]);
  };

  useEffect(() => {
    initPrices(checkout, dateRange, rates);
  }, [checkout, dateRange, rates]);

  return (
    <div className="checkout-container-modal-check">
      <div className="checkout-container-modal-check-top">
        <div className="checkout-container-modal-check-col">
          <div className="checkout-container-modal-check-col-header">
            <p>Datele tale:</p>
          </div>
          <div className="checkout-container-modal-check-col-content">
            {Object.keys(leftFields).map((el) => {
              return (
                <div className="checkout-container-modal-check-col-content-row">
                  <div className="checkout-container-modal-check-col-content-row-left">
                    <p>{el}</p>
                  </div>
                  <div className="checkout-container-modal-check-col-content-row-right">
                    <p>{leftFields[el][0]}</p>
                  </div>
                </div>
              );
            })}
            {Object.keys(rightFields).map((el) => {
              return (
                <div className="checkout-container-modal-check-col-content-row">
                  <div className="checkout-container-modal-check-col-content-row-left">
                    <p>{el}</p>
                  </div>
                  <div className="checkout-container-modal-check-col-content-row-right">
                    <p>{rightFields[el][0]}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="checkout-container-modal-check-separator"></div>
        <div className="checkout-container-modal-check-col">
          <div className="checkout-container-modal-check-col-header">
            <p>Comanda ta:</p>
          </div>
          <div className="checkout-container-modal-check-col-content">
            {extractedPrices.map((el) => {
              return (
                <div className="checkout-container-modal-check-col-content-row">
                  <div className="checkout-container-modal-check-col-content-row-right-even">
                    <p>{el["description"]}</p>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                    className="checkout-container-modal-check-col-content-row-right-even"
                  >
                    <p>x{el["quantity"]}</p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                    className="checkout-container-modal-check-col-content-row-right-even"
                  >
                    <p>{el["price"] / 100} RON</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="checkout-container-modal-check-bottom">
        <p className="checkout-container-modal-check-bottom-separator"></p>
        <p>Total: {totalSum} RON</p>
      </div>
    </div>
  );
};

// Page 3
const PageChoosePayment = ({
  totalSum,
  handleFinalStep,
  hotelId,
  paymentMethod,
  setPaymentMethod,
  user,
}) => {
  const [isUserValid, setIsUserValid] = useState(null);
  const checkIfAdminAndOwnThisProperty = () => {
    if (user == null) return false;
    if (user.role === "admin" && user.managedHotel === hotelId) {
      setIsUserValid(true);
    }
  };
  const handlePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
  };

  useEffect(() => {
    checkIfAdminAndOwnThisProperty();
  }, [user]);

  return (
    <div className="checkout-container-modal-payment">
      <p className="checkout-container-modal-payment-separator"></p>
      <p>Total: {totalSum} RON</p>
      {isUserValid === undefined ? (
        <p>Verificare...</p>
      ) : isUserValid === true ? (
        <>
          <p>
            Ești logat ca administrator. Ai posibilitatea de a crea o rezervare, sărind pasul de
            plată online (în cazul rezervărilor ce vor fi plătite fizic la locație).
          </p>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">Metoda plata</FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={paymentMethod}
              onChange={handlePaymentMethod}
            >
              <FormControlLabel value="online" control={<Radio />} label="Online" />
              <FormControlLabel
                value="fizic"
                control={<Radio />}
                label="Sari plată (doar pentru admin)"
              />
            </RadioGroup>
          </FormControl>
        </>
      ) : null}
      {isUserValid === undefined ? (
        <p>Verificare...</p>
      ) : isUserValid === null || isUserValid === false ? (
        <button onClick={handleFinalStep}>Plateste Online</button>
      ) : null}
    </div>
  );
};

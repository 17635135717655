import React from "react";
import { Link } from "react-router-dom";
import "./Style/LeftBar.css";
import { appMap } from "../../user/user-acces";

const LeftBar = ({ mode, page }) => {
  return (
    <div className="leftbar-container">
      <div className="leftbar-container-logo">
        <Link to="/" target="_blank">
          <img src="/platform/logo.svg" alt="logo" />
        </Link>
        <p className="fade-line" />
      </div>
      <div className="leftbar-container-items">
        {appMap[mode].map((menuItem, index) => (
          <Link
            to={menuItem.route}
            className={
              `leftbar-container-items-el` +
              (menuItem.route === `/${page}` ? ` leftbar-container-items-el-selected` : ``)
            }
            key={index}
          >
            <div
              className={`${
                menuItem.route === `/${page}`
                  ? "leftbar-container-items-el-img-selected"
                  : `leftbar-container-items-el-img`
              }`}
            >
              <img
                src={
                  menuItem.route === `/${page}`
                    ? `/leftbar/colored/${menuItem.icon}`
                    : `/leftbar/${menuItem.icon}`
                }
                alt={menuItem.name}
              />
            </div>
            <div className="leftbar-container-items-el-content">
              <span>{menuItem.name}</span>
            </div>
          </Link>
        ))}
      </div>
      <div className="leftbar-container-help">
        <div className="leftbar-container-help-top">
          <div className="leftbar-container-help-top-imbox">
            <img src="/leftbar/question.png" alt="question" />
          </div>
        </div>
        <div className="leftbar-container-help-text">
          <h3>Ai nevoie de ajutor ?</h3>
          <p>Contactează suport</p>
        </div>
        <div className="leftbar-container-help-btn">
          <Link to="/docs">Suport</Link>
        </div>
      </div>
    </div>
  );
};

export default LeftBar;

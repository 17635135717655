import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./Style/Receipt.css";
import {
  extractPriceWithFacilitiesFromCheckout,
  formatDateToYYYYMMDDString,
} from "../../utils/functions";
import { MagnusLiveSuportWhatsapp, SERVER_ADDR } from "../../config";
import { Link } from "react-router-dom";

export default function Receipt() {
  const location = useLocation();

  const [reservationId, setReservationId] = useState(null);
  const [reservationData, setReservationData] = useState(null);
  const [backLink, setBackLink] = useState("");

  useEffect(() => {
    if (reservationData !== null) {
      let propertyId = reservationData["checkout"]["propertyId"];
      setBackLink(`/?hotel=${propertyId}`);
    }
  }, [reservationData]);

  const [formatData, setFormatData] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const idParam = searchParams.get("id");

    if (idParam) {
      setReservationId(idParam);
    }
  }, [location.search]);

  useEffect(() => {
    if (reservationId !== null && reservationId !== "") {
      fetchReservationData(reservationId);
    }
  }, [reservationId]);

  useEffect(() => {
    if (reservationData !== null) {
      let formatDataLocal = formatAPIData(reservationData);
      setFormatData(formatDataLocal);
    }
  }, [reservationData]);

  const fetchReservationData = async (sourceId) => {
    try {
      let receiptData = await axios.get(`${SERVER_ADDR}/api/reservation/${sourceId}/receipt`);
      setReservationData(receiptData.data);
    } catch (err) {
      window.alert("Nu pot gasi aceasta rezervare!");
      console.log("Eroare la fetch rezervare:", err);
    }
  };

  const formatAPIData = (source) => {
    let createdDateFormated = new Date(source.checkout.createdAt);

    let corePageObj = {
      fieldsTop: {
        Nume: source.checkout.userFields.Nume[0] + " " + source.checkout.userFields.Prenume[0],
        Telefon: source.checkout.userFields["Numar telefon"][0],
        Checkin: source.checkout.startDate,
        Checkout: source.checkout.endDate,
      },

      createdAt: formatDateToYYYYMMDDString(createdDateFormated),
      contorIndexFactura: source.checkout.contorIndexFactura,
    };

    let extractedItems = [];

    source.checkout.checkout.forEach((checkoutItem) => {
      // add room

      let roomObj = {
        Nume: checkoutItem.roomDetailsForCheckout.name,
        qt: 1,
        Price: 100,
        bold: true,
      };
      extractedItems.push(roomObj);

      // add facilities for the current room
      extractPriceWithFacilitiesFromCheckout(checkoutItem)["items"].forEach((elFacility) => {
        let ceva = elFacility.description;

        let facilityItem = {
          Nume: ceva,
          qt: elFacility.quantity,
          Price: elFacility.price / 100,
          bold: false,
        };

        extractedItems.push(facilityItem);
      });
    });

    corePageObj["Items"] = [...extractedItems];

    return corePageObj;
  };

  return (
    <div className="status-page-cont">
      <div className="status-page-cont-core">
        {formatData == null ? (
          <p>Loading</p>
        ) : (
          <div className="status-page-cont-core-body">
            <div className="status-page-cont-core-body-back">
              <Link to={backLink} target="_blank">
                <img src="./icons/back.png" title="Inapoi" />
              </Link>
            </div>
            <div className="status-page-cont-core-body-header">
              <p>Cod comandă: {formatData.contorIndexFactura}</p>
              <span>{formatData.createdAt}</span>
            </div>
            <div className="status-page-cont-core-body-icon">
              <p>Rezervare finalizată!</p>
              <img src="./icons/succes.png" />
            </div>
            <div className="status-page-cont-core-body-top-fields">
              {Object.keys(formatData.fieldsTop).map((elKey, index) => {
                return (
                  <div className="status-page-cont-core-body-top-fields-item" key={index}>
                    <span>{elKey}</span>
                    <b>
                      <span>{formatData.fieldsTop[elKey]}</span>
                    </b>
                  </div>
                );
              })}
            </div>

            <div className="status-page-cont-core-body-top-rooms">
              <div className="status-page-cont-core-body-top-rooms-top">
                <p>Camere rezervate:</p>
              </div>
              <div className="status-page-cont-core-body-top-rooms-items">
                {formatData["Items"].map((el, index) => {
                  return (
                    <div className="status-page-cont-core-body-top-rooms-items-item" key={index}>
                      <div className="status-page-cont-core-body-top-rooms-item-left">
                        {el.bold == true ? (
                          <b>
                            <span>{el["Nume"]}</span>
                          </b>
                        ) : (
                          <>
                            &nbsp; &nbsp; &nbsp;<span>{el["Nume"]}</span>
                          </>
                        )}
                      </div>
                      <div className="status-page-cont-core-body-top-rooms-item-center">
                        <span>x{el["qt"]}</span>
                      </div>
                      <div className="status-page-cont-core-body-top-rooms-item-right">
                        <span>{el["Price"]} RON</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="status-page-cont-core-body-top-total">
              <div className="status-page-cont-core-body-top-total-left">
                <span>Total:</span>
              </div>
              <div className="status-page-cont-core-body-top-total-right">
                <span>{reservationData["Total"]} RON</span>
              </div>
            </div>

            <div className="status-page-cont-core-body-top-receipt">
              <Link to={`${SERVER_ADDR}/api/reservation/${reservationId}/receipt-pdf`} download>
                Descarcă factură
              </Link>
            </div>

            <div className="status-page-cont-core-body-top-support">
              <p>
                Ai probleme ? Contactează echipa tehnică{" "}
                <Link to={MagnusLiveSuportWhatsapp}> aici.</Link>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import React from "react";
import "./WidgetAboutMagnus.css";
import { Link } from "react-router-dom";

export default function WidgetAboutMagnus() {
  return (
    <div className="about-magnus-container">
      <div className="about-magnus-container-left">
        <div className="about-magnus-container-left-top">
          <div className="about-magnus-container-left-header">
            <p>Info</p>
          </div>
          <div className="about-magnus-container-left-title">
            <p>Despre Magnus Booking</p>
          </div>
          <div className="about-magnus-container-left-content">
            <p>
              Afla mai multe despre cum Magnus Booking iti permite sa iti construiesti propriul
              sistem de rezervari, potrivit nevoilor afacerii tale.
            </p>
          </div>
        </div>
        <div className="about-magnus-container-left-btns">
          <Link to="/">
            <p>Citeste mai mult</p>
            <img src="/adminDash/rightArrow.png" alt="redirect" />
          </Link>
        </div>
      </div>
      <div className="about-magnus-container-right">
        <img src="/landing/loginRightLogo.png" alt="logo" />
      </div>
    </div>
  );
}

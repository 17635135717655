import React, { useState, useEffect } from "react";
import "./ModalReservationUpdate";
import "./ModalReservationUpdate.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import DatePicker from "react-datepicker";
import {
  formatDateToYYYYMMDDString,
  generateBetweenDates,
  parseStringYYYYMMDDtoDate,
} from "../../../../utils/functions";
import RoomTabItem from "../RoomTabItem";
import Cookies from "js-cookie";
import { SERVER_ADDR } from "../../../../config";

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function ModalReservationUpdate({ currentReservation, closeModal, modalMode }) {
  const [aval, setAval] = useState(undefined);
  const [rates, setRates] = useState(undefined);
  const [updates, setUpdates] = useState(undefined);
  const [returSum, setReturSum] = useState(0);
  const [dayPrices, setDayPrices] = useState({}); //magic_room_1 + magic_room_2 price in the same day from the calendar
  const [dateRange, setDateRange] = useState(undefined); //datesBetween

  const [facilities, setFacilities] = useState(undefined);
  const [facilitiesHaveUpdated, setFacilitiesHaveUpdated] = useState(false);
  const [extractedUpdates, setExtractedUpdates] = useState({
    added: [],
    deleted: [],
  });

  const [oldUpdates, setOldUpdates] = useState({
    added: [],
    deleted: [],
  }); //old updates (from db)

  const [roomComments, setRoomComments] = useState(undefined);
  const [excludedDays, setExcludedDates] = useState([]);

  // Init states
  const initAval = async () => {
    let dayInMiliseconds = 86400 * 1000;
    let nrDaysOffset = 60;

    let startDate = parseStringYYYYMMDDtoDate(currentReservation["startDate"]);
    let endDate = parseStringYYYYMMDDtoDate(currentReservation["endDate"]);

    let startDateOffset = new Date(startDate.getTime() - dayInMiliseconds * nrDaysOffset);
    let endDateeOffset = new Date(startDate.getTime() + dayInMiliseconds * nrDaysOffset);

    let startDateParsed = formatDateToYYYYMMDDString(startDateOffset);
    let endDateParsed = formatDateToYYYYMMDDString(endDateeOffset);

    let propertyId = currentReservation["propertyId"];
    try {
      const config = {
        headers: {
          // you don't need auth for public route
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${SERVER_ADDR}/api/property/${propertyId}/availability?startDate=${startDateParsed}&endDate=${endDateParsed}`,
        config
      );

      setAval(data);
    } catch (err) {
      window.alert("can't fetch this hotel aval");
      console.log("can't fetch this hotel aval:", err);
    }
  };
  const initRoomsPrices = async () => {
    let dayInMiliseconds = 86400 * 1000;
    let nrDaysOffset = 60;

    let startDate = parseStringYYYYMMDDtoDate(currentReservation["startDate"]);
    let endDate = parseStringYYYYMMDDtoDate(currentReservation["endDate"]);

    let startDateOffset = new Date(startDate.getTime() - dayInMiliseconds * nrDaysOffset);
    let endDateeOffset = new Date(endDate.getTime() + dayInMiliseconds * nrDaysOffset);

    let startDateParsed = formatDateToYYYYMMDDString(startDateOffset);
    let endDateParsed = formatDateToYYYYMMDDString(endDateeOffset);

    let propertyId = currentReservation["propertyId"];

    try {
      const config = {
        headers: {
          // you don't need auth for public route
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${SERVER_ADDR}/api/property/${propertyId}/rates?startDate=${startDateParsed}&endDate=${endDateParsed}`,
        config
      );

      setRates(data);
    } catch (err) {
      window.alert("can't fetch this hotel aval");
      console.log("can't fetch this hotel rates:", err);
    }
  };
  const initUpdates = (currentResSrc) => {
    let selectedInitFacilities = currentResSrc.checkout.map((el) => {
      return { ...el.checkboxFacilities };
    });
    setUpdates({
      newDays: [],
      removedDays: [],
      // facilities: selectedInitFacilities, //remove this later, becase we are using facilities state (and compare it with currentReservation initial facilities)
    });
  };
  const extractInitFacilities = (currentResSrc) => {
    let currentReservationCopy = JSON.parse(JSON.stringify(currentResSrc)); //may god forgive me for this line of code :))
    let localFacilities = currentReservationCopy.checkout.map((roomItem) => {
      return {
        checkboxFacilities: roomItem.checkboxFacilities,
        intervalFacilities: roomItem.intervalFacilities,
        extraUsers: roomItem.extraUsers,
      };
    });
    setFacilities(localFacilities);
  };
  const extractInitRoomComments = (currentResSrc) => {
    let localInitComments = currentResSrc.checkout.map((roomItem) => {
      return {
        value: "",
      };
    });
    setRoomComments(localInitComments);
  };
  const initOldUpdates = (currentResSrc) => {
    let updates = currentResSrc["updates"];
    if (updates) {
      let parsedUpdates = JSON.parse(updates);
      setOldUpdates(parsedUpdates);
    }
  };

  // Logic
  const renderDayContents = (dayPricesSrc, dateRangeSrc, updatesSrc, day, date) => {
    if (dateRangeSrc == undefined) return;

    let dateString = formatDateToYYYYMMDDString(date);

    let price = <img src="/icons/wrong.png" />;
    if (dayPricesSrc[dateString] !== undefined) price = dayPricesSrc[dateString];

    let defaultColor = "gray";
    let newColor = "green";
    let removedColor = "red";

    let className = "reservations-container-modal-wrapper-bot-calendar-core-day";
    if (updatesSrc.newDays.includes(dateString)) {
      return (
        <p className={className} style={{ backgroundColor: newColor }}>
          {day}
          <br></br>
          {price}
        </p>
      );
    } else if (updatesSrc.removedDays.includes(dateString)) {
      return (
        <p className={className} style={{ backgroundColor: removedColor }}>
          {day}
          <br></br>
          {price}
        </p>
      );
    } else if (dateRangeSrc.includes(dateString)) {
      return (
        <p className={className} style={{ backgroundColor: defaultColor }}>
          {day}
          <br></br>
          {price}
        </p>
      );
    } else {
      return (
        <p className={className}>
          {day}
          <br></br>
          {price}
        </p>
      );
    }
  };

  const handleDayClick = (dayParam) => {
    // TODO: reguli suplimentare ca ziua sa fie neaparat prima sau ultima (pentru a pastra comanda consecutiva)

    let dayParamObj = dayParam[0];
    let dayParamStr = formatDateToYYYYMMDDString(dayParam[0]);

    if (dayPrices[dayParamStr] === undefined) {
      window.confirm("Nu poti selecta o zi pentru care nu se stie pretul camerelor!");
      return;
    }
    if (
      !dateRange.includes(dayParamStr) &&
      !updates.newDays.includes(dayParamStr) &&
      !updates.removedDays.includes(dayParamStr)
    ) {
      //clicked an empty day (possible new day)
      if (modalMode == 1) {
        window.alert("Nu poti adauga o zi noua in modul de editare 'eliminare elemente'!");
        return;
      }

      setUpdates((prev) => {
        let copy = { ...prev };
        copy.newDays.push(dayParamStr);
        return copy;
      });
    } else if (updates.removedDays.includes(dayParamStr)) {
      //clicked a removed day
      setUpdates((prev) => {
        let copy = { ...prev };

        let updatedRemoved = [];
        copy.removedDays.forEach((el) => {
          if (el !== dayParamStr) updatedRemoved.push(el);
        });
        copy["removedDays"] = updatedRemoved;
        return copy;
      });
    } else if (dateRange.includes(dayParamStr)) {
      //clicked a default day

      if (modalMode == 2) {
        window.alert("Nu poti elimina o zi din rezervare in modul de editare 'adauga elemente'!");
        return;
      }

      setUpdates((prev) => {
        let copy = { ...prev };
        copy["removedDays"].push(dayParamStr);
        return copy;
      });
    } else if (updates.newDays.includes(dayParamStr)) {
      //clicked a new day
      setUpdates((prev) => {
        let copy = { ...prev };
        let updatedNew = [];
        copy["newDays"].forEach((el) => {
          if (el !== dayParamStr) updatedNew.push(el);
        });
        copy["newDays"] = updatedNew;
        return copy;
      });
    }
  };

  const calculateExcludedDays = (avalSrc, currentReservationSrc) => {
    let currentRooms = {};
    currentReservationSrc.checkout.forEach((checkoutEl) => {
      let currentRoomId = checkoutEl.channexRoomId;
      currentRooms[currentRoomId] = avalSrc[currentRoomId];
    });
    //extrage cheile zilelor, folosind prima camera din disponibilitate
    let firstRoomKey = Object.keys(avalSrc)[0];
    let extractDaysKeys = Object.keys(avalSrc[firstRoomKey]);

    //zilele excluse sunt acelea care nu au cel putin 1 camera disponibila pentru toate tipurile de camere din comanda curenta
    let excludedDays = [];
    extractDaysKeys.forEach((dayKey) => {
      let excludeThisDay = false;
      //daca cel putin o camera din rezervare nu poate oferi aceasta zi, acesta zi devine exclusa
      Object.keys(currentRooms).forEach((roomKey) => {
        if (currentRooms[roomKey][dayKey] == 0) {
          excludeThisDay = true;
        }
      });
      if (excludeThisDay === true) excludedDays.push(dayKey);
    });
    // sterge elementele din excludedDays care reprezinta zilele default din rezervare (doresc sa pot da click pe acestea pentru a le sterge)
    excludedDays = excludedDays.filter((el) => !dateRange.includes(el));
    let formatedExcludedDays = excludedDays.map((el) => parseStringYYYYMMDDtoDate(el));
    setExcludedDates(formatedExcludedDays);
  };

  const calculateDayPrices = (ratesSrc, currentReservationSrc) => {
    // TODO: this function needs refactor to include selected package price addon for each day

    let currentRooms = [];
    currentReservationSrc.checkout.forEach((checkoutEl) => {
      let currentRoomId = checkoutEl.ratesId;
      let currentRoomRates = ratesSrc[currentRoomId];
      let currentRoomPriceAddon = checkoutEl.selectedPackage.priceAddon;

      let currentRoomRatesWithAddon = {};
      Object.keys(currentRoomRates).forEach((dayKey) => {
        currentRoomRatesWithAddon[dayKey] =
          Number(currentRoomRates[dayKey]["rate"]) + Number(currentRoomPriceAddon);
      });

      let currentRoomPayload = {
        roomId: currentRoomId,
        ratesWithAddon: currentRoomRatesWithAddon,
      };
      currentRooms.push(currentRoomPayload);
    });

    // Extract days keys
    let dayKeys = Object.keys(currentRooms[0]["ratesWithAddon"]);
    let pricesSumPerDay = {};
    dayKeys.forEach((dayKey) => {
      let currentDaySum = 0;
      currentRooms.forEach((checkoutRoomEl) => {
        currentDaySum += Number(checkoutRoomEl["ratesWithAddon"][dayKey]);
      });
      pricesSumPerDay[dayKey] = currentDaySum;
    });

    setDayPrices(pricesSumPerDay);
  };

  const returSumExtractor = (updatesSrc, facilitiesSrc, dayPricesSrc) => {
    //calculate based on update(added and removed rooms) & facilities update (compare local facilities with currentReservation facilities (initial data))
    let returnSumLocal = 0;

    let removedItemsPayload = []; //used in deleted items api call
    //format: {name: "item name", price: "item price", qty: "item qty"}

    let addedItemsPayload = []; //used in added items api call
    //format: {name: "item name", price: "item price", qty: "item qty"}

    // calculate removedDays (increase balance)
    let removedDays = updatesSrc["removedDays"];
    removedDays.forEach((dayKey) => {
      let currentDayPrice = dayPricesSrc[dayKey];
      returnSumLocal += currentDayPrice;
      removedItemsPayload.push({
        name: "Zi stearsa: " + dayKey,
        price: currentDayPrice,
        qty: 1,
      });
    });

    // calculate addedDays (decrease balance)
    let newDays = updatesSrc["newDays"];
    newDays.forEach((dayKey) => {
      let currentDayPrice = dayPricesSrc[dayKey];
      returnSumLocal -= currentDayPrice;
      addedItemsPayload.push({
        name: "Zi adaugata: " + dayKey,
        price: currentDayPrice,
        qty: 1,
      });
    });

    let facilitiesHaveUpdatedLocal = false;
    // facilities (compare facilitiesSrc (localFacilities updates) with currentReservation (initial facilities) and decide increase or decrease)

    // [0] extract initial facilities
    let initFacilities = []; //Array, becase iterates each room
    currentReservation.checkout.forEach((roomItem) => {
      let currentRoomFacilities = {
        checkboxFacilities: roomItem["checkboxFacilities"],
        extraUsers: roomItem["extraUsers"],
        intervalFacilities: roomItem["intervalFacilities"],
      };
      initFacilities.push(currentRoomFacilities);
    });

    //[1] Calculate checkboxFacilities
    let sumCheckboxFacilitiesSum = 0;
    initFacilities.forEach((roomElFacilities, indexRoom) => {
      roomElFacilities["checkboxFacilities"].forEach((checkBoxEl, indexFacility) => {
        // deleted this facility
        if (
          checkBoxEl["selected"] == true &&
          facilitiesSrc[indexRoom]["checkboxFacilities"][indexFacility]["selected"] == false
        ) {
          sumCheckboxFacilitiesSum += checkBoxEl["price"];
          facilitiesHaveUpdatedLocal = true;
          removedItemsPayload.push({
            name: checkBoxEl["name"],
            price: checkBoxEl["price"],
            qty: 1,
          });
        }
        //added this facility
        else if (
          checkBoxEl["selected"] == false &&
          facilitiesSrc[indexRoom]["checkboxFacilities"][indexFacility]["selected"] == true
        ) {
          sumCheckboxFacilitiesSum -= checkBoxEl["price"];
          facilitiesHaveUpdatedLocal = true;
          addedItemsPayload.push({
            name: checkBoxEl["name"],
            price: checkBoxEl["price"],
            qty: 1,
          });
        }
      });
    });

    //[2] Calculate interval facilities
    let sumIntervalFacilitiesSum = 0;

    initFacilities.forEach((roomElFacilities, indexRoom) => {
      roomElFacilities["intervalFacilities"].forEach((intervalEl, indexFacility) => {
        let initSelectedValue = intervalEl["selectedValue"];
        let localSelectedValue =
          facilitiesSrc[indexRoom]["intervalFacilities"][indexFacility]["selectedValue"];
        let localPrice = facilitiesSrc[indexRoom]["intervalFacilities"][indexFacility]["price"];

        let diff = localSelectedValue - initSelectedValue;
        if (diff !== 0) facilitiesHaveUpdatedLocal = true;
        let diffPrice = diff * localPrice;

        if (diffPrice > 0) {
          addedItemsPayload.push({
            name: intervalEl["name"],
            price: localPrice,
            qty: diff,
          });
        } else if (diffPrice < 0) {
          removedItemsPayload.push({
            name: intervalEl["name"],
            price: localPrice,
            qty: diff,
          });
        }

        sumIntervalFacilitiesSum -= diffPrice;
      });
    });

    returnSumLocal += sumCheckboxFacilitiesSum + sumIntervalFacilitiesSum;
    setReturSum(returnSumLocal);
    setFacilitiesHaveUpdated(facilitiesHaveUpdatedLocal);
    setExtractedUpdates((prev) => {
      let copy = { ...prev };
      copy["added"] = addedItemsPayload;
      copy["deleted"] = removedItemsPayload;
      return copy;
    });
  };

  const save = async () => {
    //UPDATES
    // verifica daca exista cel putin o modificare (la camere sau la facilitati)
    if (
      updates.newDays.length == 0 &&
      updates.removedDays.length == 0 &&
      facilitiesHaveUpdated == false
    ) {
      window.alert("Nu ai facut nici o modificare!");
    }

    if (returSum != 0 && currentReservation["paymentMethod"] == "online" && modalMode != 1) {
      window.alert(
        "Aceasta este o rezervare platita online, poti face astfel de updateuri doar daca pretul de dupa update este acelasi!"
      );
      return;
    }

    //in acest punct se poate face modificarea fara probleme
    //atach comments to rooms
    let updateExistingCheckout = []; //replace new facilities and attach room comments
    if (facilitiesHaveUpdated == true) {
      updateExistingCheckout = currentReservation.checkout.map((el, index) => {
        let updatesCopy = { ...el };
        updatesCopy["checkboxFacilities"] = facilities[index]["checkboxFacilities"];
        updatesCopy["intervalFacilities"] = facilities[index]["intervalFacilities"];
        updatesCopy["extraUsers"] = facilities[index]["extraUsers"];
        updatesCopy["roomComments"] = roomComments[index].value;
        return updatesCopy;
      });
    }

    // TODO: if modalMode == 1 (it means reservation is online and this is deletion mode)
    //extract deleted items (days and facilities) for deletionUpdates api parameter
    //to extract (name, item price, qty)

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("jwt")}`,
        },
      };
      const { data } = await axios.post(
        `${SERVER_ADDR}/api/reservation/${currentReservation._id}/admin-update`,
        {
          hotelId: currentReservation.propertyId,
          reservationId: currentReservation._id,
          roomDaysUpdates:
            updates.newDays.length == 0 && updates.removedDays.length == 0 ? null : updates,
          facilities: facilitiesHaveUpdated == true ? updateExistingCheckout : null,
          newReturnSum: returSum,
          refund: modalMode == 1 && currentReservation.paymentMethod == "online",
          updates: modalMode == 1 ? extractedUpdates : null,
        },
        config
      );
      if (data.success === true) {
        window.alert("Modificările au fost salvate cu succes!");
        window.location.reload();
      }
    } catch (err) {
      console.log("err:", err);
    }
  };

  useEffect(() => {
    initRoomsPrices();
    initAval();
    initUpdates(currentReservation);
    extractInitFacilities(currentReservation);
    extractInitRoomComments(currentReservation);
  }, []);

  useEffect(() => {
    initOldUpdates(currentReservation);
    //extract initial date range
    let startDateInitial = currentReservation["startDate"];
    let endDateInitial = currentReservation["endDate"];

    let startDateObj = parseStringYYYYMMDDtoDate(startDateInitial);
    let endDateObj = parseStringYYYYMMDDtoDate(endDateInitial);

    let datesBetween = generateBetweenDates(startDateObj, endDateObj).map((el) =>
      formatDateToYYYYMMDDString(el)
    );

    setDateRange(datesBetween);
  }, [currentReservation]);

  useEffect(() => {
    if (currentReservation !== undefined && aval !== undefined && rates !== undefined) {
      calculateExcludedDays(aval, currentReservation);
      calculateDayPrices(rates, currentReservation);
    }
  }, [currentReservation, aval, rates]);

  useEffect(() => {
    if (updates !== undefined && facilities !== undefined) {
      returSumExtractor(updates, facilities, dayPrices);
    }
  }, [updates, facilities]);

  // IGNORE BELLOW THIS LINE - Tabs logic
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const parseMode = (srcMode) => {
    if (srcMode === 0) {
      return "Modificare fara reguli (balanta trebuie sa fie 0 pentru rezervarile platitle online)";
    } else if (srcMode === 1) {
      return "Eliminare elemente partiale";
    } else if (srcMode === 2) {
      return "Adaugare elemente partiale";
    }
  };

  return (
    <div className="reservations-container-modal-wrapper">
      <div className="reservations-container-modal-wrapper-top">
        <p>
          Mod de lucru: <b>{parseMode(modalMode)}</b>
        </p>
        <p>
          Balanță: <b>{returSum} RON</b>
        </p>
      </div>
      <div className="reservations-container-modal-wrapper-bot">
        <div className="reservations-container-modal-wrapper-bot-up" style={{ display: "flex" }}>
          <div className="reservations-container-modal-wrapper-bot-calendar">
            <div className="reservations-container-modal-wrapper-bot-calendar-core">
              <DatePicker
                onChange={handleDayClick}
                monthsShown={1}
                selectsRange
                inline
                renderDayContents={(day, date) =>
                  renderDayContents(dayPrices, dateRange, updates, day, date)
                }
                excludeDates={excludedDays}
              />
            </div>
          </div>
          <div className="reservations-container-modal-wrapper-bot-tabs">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  {currentReservation.checkout.map((el, index) => {
                    return <Tab label={el.roomDetailsForCheckout.name} {...a11yProps(index)} />;
                  })}
                </Tabs>
              </Box>
              {currentReservation.checkout.map((el, index) => {
                return (
                  <CustomTabPanel value={value} index={index}>
                    <RoomTabItem
                      key={index}
                      roomItem={el}
                      index={index}
                      facilities={facilities}
                      setFacilities={setFacilities}
                      roomComments={roomComments}
                      setRoomComments={setRoomComments}
                      modalMode={modalMode}
                      currentReservation={currentReservation}
                    />
                  </CustomTabPanel>
                );
              })}
            </Box>
          </div>
        </div>
        <div className="reservations-container-modal-wrapper-bot-down">
          <div className="reservations-container-modal-wrapper-bot-calendar-results">
            <DaysWidget
              title="Zile initiale"
              items={dateRange == undefined ? [] : dateRange}
              color="gray"
            />
            <DaysWidget
              title="Zile adaugate"
              items={updates == undefined ? [] : updates.newDays}
              color="green"
            />
            <DaysWidget
              title="Zile sterse"
              items={updates == undefined ? [] : updates.removedDays}
              color="red"
            />
            <div className="reservations-container-modal-wrapper-bot-calendar-results-info">
              <img src="/icons/info_2.png" />
              <p>Dă click pe o zi pentru a o șterge sau a o adăuga.</p>
            </div>
            <div className="reservations-container-modal-wrapper-bot-calendar-results-info">
              <img src="/icons/wrong.png" />
              <p>Nu poți selecta zilele marcate astfel, deoarece prețul lor este necunoscut.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="reservations-container-modal-wrapper-footer">
        <div className="reservations-container-modal-wrapper-footer-left">
          <p>
            Acesta modificare este posibila doar in rezervari cu plata skip sau rezervari online in
            care pretul de dupa update nu se modifica.
          </p>
        </div>
        <div className="reservations-container-modal-wrapper-footer-right">
          <button onClick={save}>Salvează</button>
        </div>
      </div>
    </div>
  );
}

const DaysWidget = ({ title, items, color }) => {
  return (
    <div className="reservations-container-modal-wrapper-bot-calendar-results-dayswidget">
      <h4>{title}</h4>
      <div className="reservations-container-modal-wrapper-bot-calendar-results-dayswidget-container">
        {items.map((el) => {
          return (
            <span
              style={{
                backgroundColor: color,
              }}
            >
              {el}
            </span>
          );
        })}
      </div>
    </div>
  );
};

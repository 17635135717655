import React, { useState, useRef, useEffect } from "react";
import LighBoxScroller from "./LighBoxScroller/LighBoxScroller";

export default function LightBoxWrapper({ images }) {
  const boxRef = useRef(null);
  const [scrollDirection, setScrollDirection] = useState(1);

  useEffect(() => {
    const box = boxRef.current;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = box;

      if (
        (scrollDirection === 1 && scrollTop + clientHeight >= scrollHeight) ||
        (scrollDirection === -1 && scrollTop === 0)
      ) {
        setScrollDirection(scrollDirection * -1);
      }

      if (scrollDirection === 1) {
        box.scrollTop += 1;
      } else {
        box.scrollTop -= 1;
      }
    };

    const startScrolling = () => {
      box.intervalId = setInterval(() => {
        handleScroll();
      }, 70);
    };

    const stopScrolling = () => {
      clearInterval(box.intervalId);
    };

    box.addEventListener("mouseover", stopScrolling);
    box.addEventListener("mouseout", startScrolling);

    startScrolling();

    return () => {
      box.removeEventListener("mouseover", stopScrolling);
      box.removeEventListener("mouseout", startScrolling);
      clearInterval(box.intervalId);
    };
  }, []);

  return (
    <div className="rooms-container-item-top-left" ref={boxRef}>
      <LighBoxScroller images={images} />
    </div>
  );
}

import React from "react";
import "./Style/Footer.css";
import { Link } from "react-router-dom";

export default function Footer({ hotelData }) {
  console.log("hotelData:", hotelData);

  const extractSocial = (hotelDataSrc) => {
    let facebook = hotelDataSrc["details"]["facebookUrl"];
    let instagram = hotelDataSrc["details"]["instagramUrl"];

    return [
      ["/icons/facebook.png", "Facebook", facebook],
      ["/icons/instagram.png", "Instagram", instagram],
    ];
  };

  const extractSections = (hotelDataSrc) => {
    let landing = hotelDataSrc["details"]["landing"];
    let contact = hotelDataSrc["details"]["contactPage"];
    let liveSupport = hotelDataSrc["details"]["liveSupport"];

    return [
      ["Site de prezentare", landing],
      ["Contact", contact],
      ["Suport live", liveSupport],
    ];
  };

  const lastSectionLinks = (hotelDataSrc) => {
    return [
      ["Termeni și Condiții", `/termeni?hotel=${hotelDataSrc._id}`],
      ["Politica de Confidențialitate", `/politica?hotel=${hotelDataSrc._id}`],
    ];
  };

  return (
    <>
      {hotelData == undefined ? null : (
        <div className="footer-container">
          <div className="footer-container-left">
            <div className="footer-container-left-header">
              <img src={hotelData.landingCss.logoUrl} />
              <p>{hotelData.details.name}</p>
            </div>
            <div className="footer-container-left-desc">
              <p>{hotelData.details.description}</p>
            </div>
            <div className="footer-container-left-social">
              {extractSocial(hotelData).map((el, index) => {
                return (
                  <Link key={index} to={el[2]} target="_blank">
                    <img src={el[0]} />
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="footer-container-middle">
            <div className="footer-container-middle-header">
              <p>Sectiuni</p>
            </div>
            <div className="footer-container-middle-sections">
              {extractSections(hotelData).map((el, index) => {
                return (
                  <Link key={index} to={el[1]} target="_blank">
                    {el[0]}
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="footer-container-right">
            <div className="footer-container-middle-header">
              <p>Legal</p>
            </div>
            <div className="footer-container-middle-sections">
              {lastSectionLinks(hotelData).map((el, index) => {
                return (
                  <Link key={index} to={el[1]} target="_blank">
                    {el[0]}
                  </Link>
                );
              })}
            </div>
            <div className="footer-container-middle-legal">
              <Link to="https://anpc.ro/ce-este-sal/" target="_blank">
                <img src="/platform/anpc-sal.svg" />
              </Link>

              <Link
                to="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
                target="_blank"
              >
                <img src="/platform/litigii.svg" />
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import "./CheckoutItem.css";
import Box from "@mui/material/Box";
import Button from "../Button/Button";
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomCheckbox from "../CustomCheckBox/CustomCheckBox";
import { extractPriceWithFacilitiesFromCheckout } from "../../utils/functions";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CheckoutItem({ index, data, deleteFromChekout, setCheckout }) {
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [currentModalData, setCurrentModalData] = useState({
    image: "",
    title: "",
    price: "",
    desc: "",
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    outline: "none",
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="checkout-container-modal-details">
            <div className="checkout-container-modal-details-exit">
              <img src="/icons/exit.png" onClick={handleClose} />
            </div>
            <div className="checkout-container-modal-details-content">
              <div className="checkout-container-modal-details-content-left">
                <img src={currentModalData.image} />
              </div>
              <div className="checkout-container-modal-details-content-right">
                <div className="checkout-container-modal-details-content-right-head">
                  <p>{currentModalData.title}</p>
                  <p>{currentModalData.price} RON</p>
                </div>
                <div className="checkout-container-modal-details-content-right-content">
                  <p>{currentModalData.desc}</p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <div className="checkout-container-items-el">
        <div className="checkout-container-items-el-title">
          <div className="checkout-container-items-el-title-left">
            <p>{data.roomDetailsForCheckout.name}</p>
          </div>
          <div className="checkout-container-items-el-title-right">
            <span>Vezi facilități extra</span>
            <ExpandMore
              sx={{ margin: 0 }}
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
        </div>
        <div className="checkout-container-items-el-collapse">
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div className="checkout-container-items-el-collapse-title">
              <p>{data.selectedPackage.name}</p>
            </div>
            <div className="checkout-container-items-el-collapse-items">
              {/* checkBox Items */}
              {data.checkboxFacilities.map((el, indexCheckBoxItem) => {
                return (
                  <CheckBoxItem
                    key={indexCheckBoxItem}
                    roomIndex={index}
                    indexCheckBoxItem={indexCheckBoxItem}
                    data={el}
                    setCheckout={setCheckout}
                    handleOpen={handleOpen}
                    setCurrentModalData={setCurrentModalData}
                  />
                );
              })}
              {/* Interval Items */}
              {data.intervalFacilities.map((el, indexIntervalItem) => {
                return (
                  <IntervalItem
                    key={indexIntervalItem}
                    roomIndex={index}
                    indexIntervalItem={indexIntervalItem}
                    data={el}
                    setCheckout={setCheckout}
                    handleOpen={handleOpen}
                    setCurrentModalData={setCurrentModalData}
                  />
                );
              })}
              {/* Extra adults and childs logic */}
              {Number(data.extraUsers.maxCapacity) > Number(data.extraUsers.capacity) ? (
                <ExtraUsersItem
                  roomIndex={index}
                  extraUsersData={data.extraUsers}
                  setCheckout={setCheckout}
                  handleOpen={handleOpen}
                  setCurrentModalData={setCurrentModalData}
                />
              ) : null}
            </div>
          </Collapse>
        </div>
        <div className="checkout-container-items-el-price">
          <div className="checkout-container-items-el-price-left">
            <p>{extractPriceWithFacilitiesFromCheckout(data).total} RON</p>
          </div>
          <div className="checkout-container-items-el-price-right">
            <Button
              text="Sterge"
              onClick={() => {
                deleteFromChekout(index);
              }}
              icon="/icons/bin.png"
            />
          </div>
        </div>
      </div>
    </>
  );
}

const CheckBoxItem = ({
  roomIndex,
  indexCheckBoxItem,
  data,
  setCheckout,
  handleOpen,
  setCurrentModalData,
}) => {
  const handleChange = () => {
    setCheckout((prev) => {
      let copy = [...prev];
      let currentValue = data.selected;
      copy[roomIndex]["checkboxFacilities"][indexCheckBoxItem]["selected"] = !currentValue;
      return copy;
    });
  };

  const handleDetailsModalClick = () => {
    handleOpen();
    setCurrentModalData({
      image: data.image,
      title: data.name,
      price: data.price,
      desc: data.description,
    });
  };

  return (
    <div className="checkout-container-items-el-collapse-items-row">
      <div className="checkout-container-items-el-collapse-items-row-left">
        <CustomCheckbox checked={data.selected} onChange={handleChange} />
        <p>{data.name}</p>
        <span>
          ({data.price} RON/{data.type == "oneTime" ? "sejur" : "bucata"})
        </span>
      </div>
      <div className="checkout-container-items-el-collapse-items-row-right">
        <p onClick={handleDetailsModalClick}>detalii</p>
      </div>
    </div>
  );
};

const IntervalItem = ({
  roomIndex,
  indexIntervalItem,
  data,
  setCheckout,
  handleOpen,
  setCurrentModalData,
}) => {
  const handleIncrease = () => {
    if (Number(data.selectedValue) == Number(data.maxValue)) {
      window.alert(`Ai atins limita de ${data.name}`);
    } else {
      setCheckout((prev) => {
        let copy = [...prev];
        let currentValue = Number(data.selectedValue);
        copy[roomIndex]["intervalFacilities"][indexIntervalItem]["selectedValue"] =
          currentValue + 1;
        return copy;
      });
    }
  };
  const handleDecrease = () => {
    if (Number(data.selectedValue) == 0) {
      window.alert(`Esti deja pe 0, nu poti deselecta mai multe ${data.name}`);
    } else {
      setCheckout((prev) => {
        let copy = [...prev];
        let currentValue = Number(data.selectedValue);
        copy[roomIndex]["intervalFacilities"][indexIntervalItem]["selectedValue"] =
          currentValue - 1;
        return copy;
      });
    }
  };
  const handleDetailsModalClick = () => {
    handleOpen();
    setCurrentModalData({
      image: data.image,
      title: data.name,
      price: data.price,
      desc: data.description,
    });
  };

  return (
    <div className="checkout-container-items-el-collapse-items-row">
      <div className="checkout-container-items-el-collapse-items-row-left-magic">
        <div className="checkout-container-items-el-collapse-items-row-left-magic-input">
          <img src="/icons/minus-sign.png" onClick={handleDecrease} />
          <p>{data.selectedValue}</p>
          <img src="/icons/plus.png" onClick={handleIncrease} />
        </div>
        <div className="checkout-container-items-el-collapse-items-row-left-magic-data">
          <p>
            {data.name}{" "}
            <span>
              ({data.price} RON/{data.type == "oneTime" ? "sejur" : "bucata"})
            </span>
          </p>
        </div>
      </div>
      <div className="checkout-container-items-el-collapse-items-row-right">
        <p onClick={handleDetailsModalClick}>detalii</p>
      </div>
    </div>
  );
};

const ExtraUsersItem = ({
  roomIndex,
  extraUsersData,
  setCheckout,
  handleOpen,
  setCurrentModalData,
}) => {
  // TODO: delete data parameter (only in dev mode, resone: know to to update checkout state)

  const handleAdultIncrease = () => {
    const leftRoomSpace =
      extraUsersData.maxCapacity -
      extraUsersData.capacity -
      extraUsersData.selectedAdults -
      extraUsersData.selectedChilds * (extraUsersData.extraChildRatio / 10);

    if (leftRoomSpace >= 1) {
      //adult ratio is 1 by default
      setCheckout((prev) => {
        let copy = [...prev];
        copy[roomIndex].extraUsers.selectedAdults =
          Number(copy[roomIndex].extraUsers.selectedAdults) + 1;
        return copy;
      });
    } else {
      window.alert("Camera nu mai are spatiu pentru inca un adult!");
    }
  };

  const handleAdultDecrease = () => {
    let currentAdultsValue = extraUsersData.selectedAdults;
    if (currentAdultsValue == 0) {
      window.alert("Camera deja nu contine adulti extra!");
    } else {
      setCheckout((prev) => {
        let copy = [...prev];
        copy[roomIndex].extraUsers.selectedAdults =
          Number(copy[roomIndex].extraUsers.selectedAdults) - 1;
        return copy;
      });
    }
  };

  const handleChildIncrease = () => {
    const leftRoomSpace =
      extraUsersData.maxCapacity -
      extraUsersData.capacity -
      extraUsersData.selectedAdults -
      extraUsersData.selectedChilds * (extraUsersData.extraChildRatio / 10);

    if (leftRoomSpace >= extraUsersData.extraChildRatio / 10) {
      //child ratio is variable
      setCheckout((prev) => {
        let copy = [...prev];
        copy[roomIndex].extraUsers.selectedChilds =
          Number(copy[roomIndex].extraUsers.selectedChilds) + 1;
        return copy;
      });
    } else {
      window.alert("Camera nu mai are spatiu pentru inca un copil!");
    }
  };

  const handleChildDecrese = () => {
    let currentChildsValue = extraUsersData.selectedChilds;
    if (currentChildsValue == 0) {
      window.alert("Camera deja nu contine copii extra!");
    } else {
      setCheckout((prev) => {
        let copy = [...prev];
        copy[roomIndex].extraUsers.selectedChilds =
          Number(copy[roomIndex].extraUsers.selectedChilds) - 1;
        return copy;
      });
    }
  };

  const handleDetailsClickAdult = () => {
    handleOpen();
    setCurrentModalData({
      image: "/icons/adult.png",
      title: "Loc Suplimentar adult",
      price: extraUsersData.extraAdultPrice,
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    });
  };

  const handleDetailsClickChild = () => {
    handleOpen();
    setCurrentModalData({
      image: "/icons/child.png",
      title: "Loc Suplimentar copil",
      price: extraUsersData.extraChildPrice,
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    });
  };

  return (
    <div className="checkout-container-items-el-collapse-items-extra-users">
      <div className="checkout-container-items-el-collapse-items-extra-users-header">
        <p>Adauga persoane extra:</p>
      </div>
      <div className="checkout-container-items-el-collapse-items-row">
        <div className="checkout-container-items-el-collapse-items-row-left-magic">
          <div className="checkout-container-items-el-collapse-items-row-left-magic-input">
            <img src="/icons/minus-sign.png" onClick={handleAdultDecrease} />
            <p>{extraUsersData.selectedAdults}</p>
            <img src="/icons/plus.png" onClick={handleAdultIncrease} />
          </div>
          <div className="checkout-container-items-el-collapse-items-row-left-magic-data">
            <p>
              Extra adult<span>({extraUsersData.extraAdultPrice} RON/noapte)</span>
            </p>
          </div>
        </div>
        <div className="checkout-container-items-el-collapse-items-row-right">
          <p onClick={handleDetailsClickAdult}>detalii</p>
        </div>
      </div>
      <div className="checkout-container-items-el-collapse-items-row">
        <div className="checkout-container-items-el-collapse-items-row-left-magic">
          <div className="checkout-container-items-el-collapse-items-row-left-magic-input">
            <img src="/icons/minus-sign.png" onClick={handleChildDecrese} />
            <p>{extraUsersData.selectedChilds}</p>
            <img src="/icons/plus.png" onClick={handleChildIncrease} />
          </div>
          <div className="checkout-container-items-el-collapse-items-row-left-magic-data">
            <p>
              Extra copil {`(< 12 ani)`}
              <span>({extraUsersData.extraChildPrice} RON/noapte)</span>
            </p>
          </div>
        </div>
        <div className="checkout-container-items-el-collapse-items-row-right">
          <p onClick={handleDetailsClickChild}>detalii</p>
        </div>
      </div>
    </div>
  );
};

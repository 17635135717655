import React from "react";
import "./HotelDetailsTitle.css";

export default function HotelDetailsTitle({ title }) {
  return (
    <div className="hoteldetails-title-container">
      <p>{title}</p>
      <div className="hoteldetails-title-container-border" />
    </div>
  );
}

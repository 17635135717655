import Box from "@mui/material/Box";
import "./Style/CalendarWithPrice.css";
import Modal from "@mui/material/Modal";
import DatePicker from "react-datepicker";
import { useState, useEffect } from "react";
import CustomButton from "../../atoms/Button/Button";
import "react-datepicker/dist/react-datepicker.css";

import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import {
  formatDateToYYYYMMDDString,
  generateBetweenDates,
  parseStringYYYYMMDDtoDate,
} from "../../utils/functions";

export default function CalendarWithPrice({
  buttonText,
  dateRange,
  setDateRange,
  rates,
  aval,
  handleButtonSearchRooms,
}) {
  const [open, setOpen] = useState(false);
  const [excludedDates, setExcludedDates] = useState([null, null]);

  const [localDateRange, setLocalDateRange] = useState([]);
  const [saveEnabled, setSaveEnabled] = useState(false);

  useEffect(() => {
    if (
      dateRange[0] !== null &&
      localDateRange[0] !== undefined &&
      localDateRange[0] !== null &&
      localDateRange[1] !== null
    ) {
      if (
        dateRange[0].getTime() !== localDateRange[0].getTime() ||
        dateRange[1].getTime() !== localDateRange[1].getTime()
      ) {
        setSaveEnabled(true);
      }
    }
  }, [dateRange, localDateRange]);

  useEffect(() => {
    if (typeof dateRange[0] === "object" && typeof dateRange[1] === "object") {
      setLocalDateRange([dateRange[0], dateRange[1]]);
    }
  }, [dateRange]);

  useEffect(() => {
    if (aval !== undefined) {
      let excludedDaysFormated = [];
      Object.keys(aval).forEach((dayEl) => {
        if (aval[dayEl] === 0) {
          excludedDaysFormated.push(parseStringYYYYMMDDtoDate(dayEl));
        }
      });

      setExcludedDates(excludedDaysFormated);
    }
  }, [aval]);

  // const isDateValid = (date) => {
  //   let dateAsString = formatDateToYYYYMMDDString(new Date(date));

  //   if (aval[dateAsString] === 0 || aval[dateAsString] === undefined) {
  //     return false;
  //   }
  //   return true;
  // };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    if (start !== null && end !== null) {
      let daysInRange = generateBetweenDates(start, end);

      let isRangeValid = true; //all days in range are available (no one is excluded)
      let extractedInvalidDates = [];

      daysInRange.forEach((day) => {
        excludedDates.forEach((excludedDay) => {
          if (excludedDay.getTime() === day.getTime()) {
            isRangeValid = false;
            extractedInvalidDates.push(formatDateToYYYYMMDDString(day));
          }
        });
      });

      if (isRangeValid === false) {
        let extractedInvalidDatesFormated = "";
        extractedInvalidDates.forEach((el) => {
          extractedInvalidDatesFormated += el + " ";
        });
        alert(`Perioada selectată conține zile indisponibile! (${extractedInvalidDatesFormated})`);

        return;
      }
    }
    setLocalDateRange([start, end]);
  };

  const getPriceForDate = (date) => {
    let dateAsStringKey = formatDateToYYYYMMDDString(date);

    let tryToExtract = rates[dateAsStringKey];
    if (tryToExtract === undefined) {
      return undefined;
    } else {
      return Number(tryToExtract.rate).toFixed(0);
    }
  };

  const renderDayContents = (day, date) => {
    const price = getPriceForDate(date);

    // const price = 100;
    return (
      <div className="react-datepicker-custom-day-core">
        <p className="react-datepicker-custom-day-core-top">
          {day}
          <>{price !== undefined ? <img src="/icons/calendarMoon.png" alt="night" /> : null}</>
        </p>
        {price !== undefined ? (
          <p className="react-datepicker-custom-day-core-bot">{price} RON</p>
        ) : null}
      </div>
    );
  };

  const save = () => {
    if (localDateRange[1] === null) {
      setDateRange([localDateRange[0], localDateRange[0]]);
      localDateRange([localDateRange[0], localDateRange[0]]);
    } else {
      setDateRange(localDateRange);
      handleButtonSearchRooms(localDateRange);
    }
    setOpen(false);
  };

  const handleClose = () => {
    if (saveEnabled === true) {
      setOpenConfirm(true);
    }
  };

  const style = {
    position: "absolute",
    top: "10%",
    left: "15%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    outline: "none",
  };

  const [openConfirm, setOpenConfirm] = useState(false);

  return (
    <>
      <Dialog
        open={openConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ești sigur ca dorești să ieși fără să salvezi ?"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirm(false);
            }}
          >
            Rămăi
          </Button>
          <Button
            onClick={async () => {
              setOpen(false);
              setOpenConfirm(false);
              setLocalDateRange(dateRange);
            }}
            autoFocus
          >
            Ieși
          </Button>
        </DialogActions>
      </Dialog>
      <div className="calendarwithprice">
        <CustomButton
          text={buttonText}
          onClick={() => {
            setOpen(true);
          }}
          icon="/icons/prices.png"
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="calendarwithprice-core">
              <DatePicker
                selected={localDateRange[0]}
                onChange={handleDateChange}
                startDate={localDateRange[0]}
                endDate={localDateRange[1]}
                monthsShown={2}
                selectsRange
                inline
                renderDayContents={renderDayContents}
                excludeDates={excludedDates}
                minDate={new Date()}
                // filterDate={isDateValid}
              />
              <div className="calendarwithprice-core-save">
                {saveEnabled === true ? (
                  <button className="calendarwithprice-core-save-enabled" onClick={save}>
                    Salvează
                  </button>
                ) : (
                  <button className="calendarwithprice-core-save-disabled" onClick={save}>
                    Salvează
                  </button>
                )}

                <div className="calendarwithprice-core-save-warning">
                  {saveEnabled === true ? (
                    <>
                      <img src="/icons/danger.png" alt="danger" />
                      <span>Atenție! Trebuie să salvezi modificarile!</span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

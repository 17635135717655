// Type 1 resources (simple key value)
export const setup = {
  channexToken: "string",
  stripeToken: "string",
  channexPropertyId: "string",
  paymentMethod: "string",
  // receiptIndex: "number",
  websiteTextBanner: "string",
  checkInHour: "string",
  checkOutHour: "string",
  email: "string",
  terms: "string",
  policy: "string",
  taxpayer: "string", //yes || no
  cif: "233d23d33",
  reg: "JD.23rd2",
  iban: "RO2342d3d43",
};

export const landingCss = {
  baseColor: "string",
  secondaryColor: "string",
  logoUrl: "string",
};

export const srlDetails = {
  name: "string",
  address: "string",
};

export const details = {
  name: "string",
  address: "string",
  phone: "string",
  description: "string",
  facebookUrl: "string",
  instagramUrl: "string",
  landing: "string",
  contactPage: "string",
  liveSupport: "string",
};

export const roomsBaseline = {
  channexRoomId: "string",
  ratesId: "string",
  name: "string",
  description: "string",
  capacity: "number",
  // new fix
  maxCapacity: "number",
  extraAdultPrice: "number",
  extraAdultDescription: "string",

  extraChildPrice: "number",
  extraChildDescription: "string",
  extraChildRatio: "number", //(0< x < 10)
  thresholdChildAge: "number", // (>0)
};

export const packagesConfig = {
  name: "string",
  priceAddon: "number",
  description: "string",
  type: "string", //('refundable' | 'nonRefundable')
  priceType: "string", //('perDayPerUser' | 'perDay')
};

export const checkboxFacilities = {
  name: "string",
  price: "number",
  image: "string",
  type: "string", //(‘daily’ | ‘oneTime’)
  description: "string",
};

export const intervalFacilities = {
  name: "string",
  price: "number",
  image: "string",
  type: "string", //(‘daily’ | ‘oneTime’)
  description: "string",
  maxValue: "number",
};
// TEST STATES
// TODO: replicate this object with empty values in order to simultate the creation of a new hotel
// the above example shows how to modify a existing one (use case: receive such an object from the API => introduce it into editor => edit => send back to API)
export const simulateAPISource = {
  setup: {
    channexToken: "aaaaaaa",
    stripeToken: "string",
    channexPropertyId: "a1c57ab8-9afc-4e6c-b3b5-cf5154f12444",
    paymentMethod: "online",
    // receiptIndex: 0,
    websiteTextBanner: "Banner - best Chalet in Predeal",
    checkInHour: "13:00",
    checkOutHour: "11:00",
    email: "amamontis@gmail.com",
    terms: "terms example",
    policy: "policy example",
    taxpayer: "yes",
    cif: "233d23d33",
    reg: "JD.23rd2",
    iban: "RO2342d3d43",
  },
  landingCss: {
    baseColor: "#d9bb80",
    secondaryColor: "rgba(217, 187, 128, 0.4)",
    logoUrl: "https://cdn-icons-png.flaticon.com/512/5094/5094746.png",
  },
  srlDetails: {
    name: "Magic S.R.L",
    address: "Predeal, Valea Morii, nr. 23",
  },
  details: {
    name: "Magic Chalet",
    address: "Predeal Valea Bradului, nr. 12",
    phone: "+40 754 223 123",
    description: "Best chalet in Predeal",
    facebookUrl: "https://www.google.com",
    instagramUrl: "https://www.youtube.com",

    landing: "https://bucuriamuntelui.ro/",
    contactPage: "https://bucuriamuntelui.ro/contact.html",
    liveSupport:
      "https://api.whatsapp.com/send/?phone=40759334719&text&type=phone_number&app_absent=0",
  },
  photos: [
    "https://images.unsplash.com/photo-1566662340221-f52df2e19ea4?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1566662340221-f52df2e19ea4?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1505322491781-b5b70b3f370c?q=80&w=1500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  ],
  rooms: [
    {
      channexRoomId: "52a68ce0-9851-4d7b-acd5-e2cfd15988b2",
      ratesId: "230b641f-b291-4a62-ac11-0ed3e1234288",
      name: "Simple room",
      description: "Magic room 1 desc",
      capacity: 2,
      maxCapacity: 3,
      extraAdultPrice: 100,
      extraAdultDescription: "Extra adult desc magic room 1",

      extraChildPrice: 55,
      extraChildDescription: "Extra child desc magic room 1",
      extraChildRatio: 5, //(0< x < 10)
      thresholdChildAge: 12, // (>0)

      facilities: ["Balcon", "Televizor LCD"],
      photos: [
        "https://images.unsplash.com/photo-1533673662755-98c661c601a1?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1501685532562-aa6846b14a0e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://plus.unsplash.com/premium_photo-1661884697940-05d49d3a3422?q=80&w=2019&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      packages: [
        {
          name: "Pachet Simplu",
          priceAddon: 0,
          description: "Nu include mic dejuns",
          type: "refundable",
          priceType: "perDay",
        },
        {
          name: "Pachet mic dejuns",
          priceAddon: 100,
          description: "Include mic dejuns",
          type: "refundable",
          priceType: "perDayPerUser",
        },
        {
          name: "Pachet premium",
          priceAddon: 150,
          description: "Include mic dejuns si acces la sauna",
          type: "nonRefundable",
          priceType: "perDay",
        },
      ],
      checkboxFacilities: [
        {
          name: "Acces Piscina",
          price: 50,
          image: "https://cdn-icons-png.flaticon.com/512/2784/2784593.png",
          type: "oneTime",
          description: "Best description for the pool facility",
        },
        {
          name: "Acces Sauna",
          price: 90,
          image: "https://cdn-icons-png.flaticon.com/512/3977/3977048.png",
          type: "oneTime",
          description: "Best description for the sauna facility",
        },
      ],
      intervalFacilities: [
        {
          name: "Acces Spa",
          price: 200,
          image: "https://cdn-icons-png.flaticon.com/512/887/887350.png",
          type: "daily", //(‘daily’ | ‘oneTime’)
          description: "Description acces spa (se cumpara 1 acces pentru fiecare persoana)",
          maxValue: 10,
        },
        {
          name: "Tur ATV",
          price: 150,
          image: "https://cdn-icons-png.flaticon.com/512/2060/2060494.png",
          type: "daily", //(‘daily’ | ‘oneTime’)
          description: "Description acces ATV (se cumpara 1 acces pentru fiecare persoana)",
          maxValue: 10,
        },
      ],
    },
    {
      channexRoomId: "64c777da-80f2-4e5c-a72b-100be84cdb7c",
      ratesId: "aa39ef76-e262-4f86-96b2-2faee0d63728",
      name: "Magic Room 2",
      description: "Magic room 2 desc",
      capacity: 3,
      maxCapacity: 4,
      extraAdultPrice: 150,
      extraAdultDescription: "Extra adult desc magic room 2",

      extraChildPrice: 75,
      extraChildDescription: "Extra child desc magic room 2",
      extraChildRatio: 5, //(0< x < 10)
      thresholdChildAge: 12, // (>0)

      facilities: ["Balcon", "Televizor LCD", "Terasa", "Bar in camera"],
      photos: [
        "https://images.unsplash.com/photo-1574276323455-8333699f7358?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1514774849407-0bef2676db20?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1591825381767-c7137b8eda0f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      packages: [
        {
          name: "Pachet Basic",
          priceAddon: 0,
          description: "Nu include mic dejuns",
          type: "refundable",
          priceType: "perDayPerUser",
        },
        {
          name: "Pachet mic dejuns",
          priceAddon: 200,
          description: "Include mic dejuns",
          type: "refundable",
          priceType: "perDay",
        },
      ],
      checkboxFacilities: [
        {
          name: "Acces Piscina",
          price: 50,
          image: "https://cdn-icons-png.flaticon.com/512/2784/2784593.png",
          type: "oneTime",
          description: "Best description for the pool facility",
        },
        {
          name: "Acces Sauna",
          price: 90,
          image: "https://cdn-icons-png.flaticon.com/512/3977/3977048.png",
          type: "oneTime",
          description: "Best description for the sauna facility",
        },
      ],
      intervalFacilities: [
        {
          name: "Acces Spa",
          price: 200,
          image: "https://cdn-icons-png.flaticon.com/512/887/887350.png",
          type: "daily", //(‘daily’ | ‘oneTime’)
          description: "Description acces spa (se cumpara 1 acces pentru fiecare persoana)",
          maxValue: 10,
        },
        {
          name: "Tur ATV",
          price: 150,
          image: "https://cdn-icons-png.flaticon.com/512/2060/2060494.png",
          type: "daily", //(‘daily’ | ‘oneTime’)
          description: "Description acces ATV (se cumpara 1 acces pentru fiecare persoana)",
          maxValue: 10,
        },
      ],
    },
  ],
};

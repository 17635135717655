import React, { useState, useEffect } from "react";
import DashboardTemplate from "./DashboardTemplate";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { SERVER_ADDR } from "../../config";
import { appMap } from "../../user/user-acces";

const ProtectedRoute = ({ availableTo, page, Target }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  const checkToken = async () => {
    const myToken = Cookies.get("jwt");
    if (myToken === undefined) navigate("/login");

    try {
      const { data } = await axios.get(`${SERVER_ADDR}/api/token`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
        },
      });

      let extractedRole = data.user.role;
      if (extractedRole !== availableTo) {
        const firstPageAfterLogin = `${appMap[extractedRole][0]["route"]}`;
        if (firstPageAfterLogin !== undefined) {
          navigate(firstPageAfterLogin);
        } else {
          navigate("/login");
        }
      }
      setUser(data.user);
      setIsLoading(false);
    } catch (err) {
      navigate("/login");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const decideComponent = (currentState) => {
    switch (currentState) {
      case true:
        return <LoadingComponent />;
      case false:
        return (
          <>
            {
              <DashboardTemplate
                availableTo={availableTo}
                Target={Target}
                user={user}
                page={page}
              />
            }
          </>
        );
      default:
        return <LoadingComponent />;
    }
  };

  return <>{decideComponent(isLoading)}</>;
};

const LoadingComponent = () => {
  return <p>Loading</p>;
};

export default ProtectedRoute;

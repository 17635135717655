import {useState, useEffect} from "react";
import "./AdminOverview.css";

export default function AdminOverview({stats}) {
  const [data, setData] = useState(null);
  
  useEffect(()=>{
    if(stats !== null)
    {
      const localData = [
        {
          name: "Incasari luna aceasta",
          value: `RON ${stats["current"]["revenue"]}`,
          icon: "/adminDash/wallet.png",
        },
        {
          name: "Rezervari luna acesta",
          value: `${stats["current"]["counter"]}`,
          icon: "/adminDash/booking.png",
        },
        {
          name: "Incasari luna viitoare",
          value: `RON ${stats["future"]["revenue"]}`,
          gain: stats["future"]["gainRevenue"],
          icon: "/adminDash/wallet.png",
        },
        {
          name: "Rezervari luna viitoare",
          value: `${stats["future"]["counter"]}`,
          gain: stats["future"]["gainCounter"],
          icon: "/adminDash/booking.png",
        },
      ];
      setData(localData)
    }
  },[stats])

  const gainColor = (value)=>{
    if(value <0)
    {
      return <span style={{color: 'red'}}>{value}%</span>
    }
    else if(value === 0)
    {
      return <span>{value}</span>
    }
    else if (value > 0)
    {
      return <span style={{color: 'green'}}>+{value}%</span>
    }
  }
  return (
    <div className="admin-overview-container">
      {data?.map((el, index) => {
        return (
          <div
            className="admin-overview-container-section"
            style={{
              marginRight: `${data.length - 1 === index ? "0px" : "20px"}`,
            }}
          >
            <div className="admin-overview-container-section-left">
              <div className="admin-overview-container-section-left-top">
                <p>{el.name}</p>
              </div>
              <div className="admin-overview-container-section-left-bot">
                <p>{el.value}</p>
                {el.gain ? gainColor(el.gain):''}
              </div>
            </div>
            <div className="admin-overview-container-section-right">
              <div className="admin-overview-container-section-right-core">
                <img src={el.icon} alt="icon" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

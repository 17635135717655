export const appMap = {
  admin: [
    {
      route: "/admin",
      name: "Acasă",
      icon: "admin.png",
    },
    {
      route: "/reservations",
      name: "Rezervări",
      icon: "rezervari.png",
    },
    {
      route: "/account",
      name: "Cont",
      icon: "cont.png",
    },
    {
      route: "/history",
      name: "Evenimente",
      icon: "istoric.png",
    },
    {
      route: "/indexes",
      name: "Serii facturi",
      icon: "receipt.png",
    },
    {
      route: "/docs",
      name: "Tutoriale",
      icon: "docs.png",
    },
  ],
  sudo: [
    {
      route: "/sudo",
      name: "Sudo",
      icon: "sudo.png",
    },
    {
      route: "/add-property",
      name: "Adaugă proprietate",
      icon: "add-prop.png",
    },
    {
      route: "/edit-property",
      name: "Editează proprietate",
      icon: "edit-prop.png",
    },
    {
      route: "/create-account",
      name: "Crează cont",
      icon: "create.png",
    },
    {
      route: "/accounts",
      name: "Conturi",
      icon: "accounts.png",
    },
  ],
};

import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { SERVER_ADDR } from "../../config";

export default function Accounts() {
  const [users, setUsers] = useState([]);
  const getUsers = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(`${SERVER_ADDR}/api/user/`, config);
      setUsers(data);
    } catch (err) {
      window.alert("Can't get users!");
    }
  };

  const handleDelete = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.delete(`${SERVER_ADDR}/api/user/${id}`, config);

      window.alert("User deleted!");
      window.location.reload();
    } catch (err) {
      console.log("can't delete user:", err);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <h4>Accounts</h4>
      {users.map((el, index) => {
        return (
          <div key={index}>
            <p>id: {el._id}</p>
            <p>email: {el.email}</p>
            <p>name:{el.name}</p>
            <p>role:{el.role}</p>
            <p>managedHotel: {el.managedHotel}</p>
            <button
              className="sudo-btn-action"
              onClick={() => {
                handleDelete(el._id);
              }}
            >
              Delete
            </button>
          </div>
        );
      })}
    </>
  );
}

import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Login from "./Login";
import { SERVER_ADDR } from "../../config";
import CircularLoading from "../../atoms/CircularLoading/CircularLoading";

export default function LoginWrapper() {
  const [skipLanding, setSkipLanding] = useState(null);
  const navigate = useNavigate();

  const checkToken = async () => {
    const myToken = Cookies.get("jwt");
    if (myToken === undefined) {
      setSkipLanding(false);
      return;
    }

    try {
      const { data } = await axios.get(`${SERVER_ADDR}/api/token`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
        },
      });

      let role = data.user.role;
      if (role === "admin") {
        navigate("/admin");
      } else if (role == "sudo") {
        navigate("/sudo");
      }
    } catch (err) {
      navigate("/login");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const LoadingWidget = () => {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <CircularLoading />
      </div>
    );
  };
  return <>{skipLanding === null ? <LoadingWidget /> : skipLanding === false ? <Login /> : null}</>;
}

import axios from "axios";
import "./Style/Policy.css";
import { SERVER_ADDR } from "../../config";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TopBar from "../../components/Landing/TopBar";
import Footer from "../../components/Landing/Footer";

export default function Policy() {
  const location = useLocation();
  const [hotelId, setHotelId] = useState(); //query parameter
  const [hotelData, setHotelData] = useState();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const hotel = queryParams.get("hotel");

    if (hotel == null || hotel == undefined) {
      window.location.href = "/fallback";
    } else {
      setHotelId(hotel);
    }
  }, []);

  useEffect(() => {
    if (hotelId !== null && hotelId !== undefined) {
      fetchHotel(hotelId);
    }
  }, [hotelId]);

  const fetchHotel = async (hotelId) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(`${SERVER_ADDR}/api/property/${hotelId}`, config);
      setHotelData(data);
    } catch (err) {
      window.location.href = "/fallback";
    }
  };

  const createMarkup = (termsSource) => {
    return { __html: termsSource };
  };

  return (
    <>
      {hotelData == null ? (
        <p>Loading...</p>
      ) : (
        <div className="policy-container">
          {hotelData ? (
            <>
              <TopBar hotelData={hotelData} />
              <div className="terms-container-core">
                {hotelData == undefined ? null : (
                  <div dangerouslySetInnerHTML={createMarkup(hotelData.setup.policy)} />
                )}
              </div>
              <Footer hotelData={hotelData} />
            </>
          ) : null}
        </div>
      )}
    </>
  );
}

import {useState,useEffect} from "react";
import "./WidgetGraph.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Incasari & Rezervari",
    },
  },
};

const labels = ["Urmatoarele 30 de zile", "Urmatoarele de la 30 la 60 de zile"];

export default function WidgetGraph({stats}) {
  const [data,setData] = useState(null);

  useEffect(()=>{
    if(stats !== null)
    {
      let first = [stats["current"]["revenue"],stats["current"]["counter"]]
      let second = [stats["future"]["revenue"],stats["future"]["counter"]]
    
      setData({
        labels,
        datasets: [
          {
            label: "Incasari",
            data: first,
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Rezervari",
            data: second,
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      })
    }
  },[stats])

  return (
    <div className="widget-graph-container">
      {
        data ?  <Bar options={options} data={data} /> : null
      }
    </div>
  );
}

import axios from "axios";
import "./Style/Login.css";
import Cookies from "universal-cookie";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { SERVER_ADDR } from "../../config";
import Snackbar from "@mui/material/Snackbar";
import { useNavigate } from "react-router-dom";
import { appMap } from "../../user/user-acces";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function Login() {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [alertValue, setAlertValue] = useState({
    open: false,
    severity: "success",
    text: "---",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (e) => {
    setUserData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const LoginHandler = async (e) => {
    e.preventDefault();
    if (userData.email !== "" && userData.password !== "") {
      try {
        const resp = await axios.post(`${SERVER_ADDR}/api/login`, {
          email: userData.email,
          password: userData.password,
        });

        const token = resp.data.token;
        cookies.set("jwt", token, { secure: true, sameSite: "none" });

        const userRole = resp.data.user.role;
        const firstPageAfterLogin = `${appMap[userRole][0]["route"]}`;

        setAlertValue(() => {
          return {
            open: true,
            severity: "success",
            text: "Conectare reușită!",
          };
        });

        setTimeout(() => {
          navigate(firstPageAfterLogin);
        }, 500);
      } catch (err) {
        setAlertValue(() => {
          return {
            open: true,
            severity: "error",
            text: `Eroare la conectare! (${err.response.data.error})`,
          };
        });
      }
    } else {
      setAlertValue(() => {
        return {
          open: true,
          severity: "warning",
          text: "Completează toate cămpurile!",
        };
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertValue((prev) => {
      let copy = { ...prev };
      copy["open"] = false;
      return copy;
    });
  };

  return (
    <>
      <Snackbar open={alertValue.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alertValue.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertValue.text}
        </Alert>
      </Snackbar>
      <div className="login-container">
        <div className="login-container-wrapper" id="top">
          <div className="login-container-wrapper-core">
            <div className="login-container-wrapper-core-left">
              <div className="login-container-wrapper-core-left-core">
                <div className="login-container-wrapper-core-left-core-top">
                  <h1>Bine ai venit inapoi</h1>
                  <p>Contectează-te la Magnus Booking</p>
                </div>
                <form onSubmit={LoginHandler}>
                  <div className="login-container-wrapper-core-left-core-form">
                    <div className="login-container-wrapper-core-left-core-form-section">
                      <TextField
                        id="outlined-basic"
                        label="Adresă de email"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        name="email"
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="login-container-wrapper-core-left-core-form-section">
                      <FormControl variant="outlined" sx={{ width: "100%" }}>
                        <InputLabel htmlFor="outlined-adornment-password">Parolă</InputLabel>
                        <OutlinedInput
                          name="password"
                          onChange={handleInputChange}
                          required
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="login-container-wrapper-core-left-core-button">
                    <button type="submit">AUTENTIFICARE</button>
                    <div className="login-container-wrapper-core-left-core-button-support">
                      <p>
                        Nu ai cont ? Contacteza <Link to="/">Suport</Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="login-container-wrapper-core-right">
              <div className="login-container-wrapper-core-right-layer">
                <img src="/landing/loginRightLogo.png" alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

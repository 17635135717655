import CalendarWithPrice from "./CalendarWithPrice";
import "./Style/Rooms.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Button from "../../atoms/Button/Button";
import {
  extractDateRangeNumber,
  formatDateToYYYYMMDDString,
  generateBetweenDates,
} from "../../utils/functions";
import LightBoxWrapper from "../../atoms/LightBoxWrapper";

export default function Rooms({
  hotelData,
  aval,
  rates,
  checkout,
  addToCheckout,
  dateRange,
  setDateRange,
  handleButtonSearchRooms,
}) {
  return (
    <div className="rooms-container">
      <div className="rooms-container-holder">
        {hotelData.rooms.map((el, index) => {
          return (
            <RoomEl
              roomItem={el}
              key={index}
              aval={aval}
              rates={rates}
              checkout={checkout}
              addToCheckout={addToCheckout}
              dateRange={dateRange}
              setDateRange={setDateRange}
              handleButtonSearchRooms={handleButtonSearchRooms}
            />
          );
        })}
      </div>
    </div>
  );
}

const RoomEl = ({
  roomItem,
  aval,
  rates,
  checkout,
  addToCheckout,
  dateRange,
  setDateRange,
  handleButtonSearchRooms,
}) => {
  const handleAddToCheckout = (selectedPackItemParam, priceEntryPoint) => {
    // priceEntryPoint = total price for selected pack for selected range
    // add to checkout clicked packType and roomItem (roomItem is used to extract facilities and channexRoomId)
    addToCheckout(selectedPackItemParam, roomItem, priceEntryPoint);
  };

  const calculateMinimumDisponibility = (roomItemSrc, avalSrc, dateRangeSrc) => {
    //date between range (selected range by the user)
    const datesBetweenRange = generateBetweenDates(dateRangeSrc[0], dateRangeSrc[1]).map((el) => {
      return formatDateToYYYYMMDDString(el);
    });

    // Returns the minimum available rooms number IN THE SPECIFIED RANGE
    // the aval range is bigger than the specified range

    if (roomItemSrc == undefined) return "Nu se poate gasi camera";
    if (avalSrc == undefined) return "Se incarca disponibiliatea...";
    if (rates == undefined) return "Se incarca preturile...";

    const extractAvailability = avalSrc[roomItemSrc.channexRoomId];

    let minAvalRooms = 100;
    //for each day in selected range, check its aval and find the min
    datesBetweenRange.forEach((el) => {
      if (extractAvailability[el] < minAvalRooms) {
        minAvalRooms = extractAvailability[el];
      }
    });

    // From the api aval number, substract selected rooms of this type in the checkout at this moment
    let appereancesInCheckout = countRoomTypeInCheckout(roomItemSrc.channexRoomId, checkout);

    return minAvalRooms - appereancesInCheckout;
  };

  const countRoomTypeInCheckout = (roomType, checkoutSrc) => {
    //Count how many this is 'roomType' appear in checkout
    let counter = 0;
    checkoutSrc.forEach((el) => {
      if (el.channexRoomId == roomType) {
        counter++;
      }
    });
    return counter;
  };

  const calculatePriceForSelectedDays = (roomItemSrc, dateRangeSrc, ratesSrc, packItemSrc) => {
    if (ratesSrc == undefined) return;
    const currentRatesId = roomItemSrc["ratesId"];
    const datesBetween = generateBetweenDates(dateRangeSrc[0], dateRangeSrc[1]).map((el) =>
      formatDateToYYYYMMDDString(el)
    );
    const currentRoomRates = ratesSrc[currentRatesId];
    let sum = datesBetween.length * packItemSrc["priceAddon"]; //init with addon price for the current pack

    datesBetween.forEach((day) => {
      sum += Number(currentRoomRates[day]["rate"]);
    });

    return sum;
  };

  return (
    <div className="rooms-container-item">
      <div className="rooms-container-item-top">
        <LightBoxWrapper images={roomItem.photos} />
        <div className="rooms-container-item-top-right">
          <div className="rooms-container-item-top-right-top">
            <h2>{roomItem.name}</h2>
            <p>{roomItem.description}</p>
          </div>
          <div className="rooms-container-item-top-right-center">
            {roomItem.facilities.map((elFacility, index) => {
              return (
                <div key={index} className="rooms-container-item-top-right-centerfacility">
                  <img src="/icons/facility.png " />
                  <span>{elFacility}</span>
                </div>
              );
            })}
          </div>
          <div className="rooms-container-item-top-right-bottom">
            <div className="rooms-container-item-top-right-bottom-left">
              <div className="rooms-container-item-top-right-bottom-left-top">
                <p>Capacitate:</p>
              </div>
              <div className="rooms-container-item-top-right-bottom-left-bot">
                <img src="/icons/group.png" />
                <p>{roomItem.capacity}x persoane</p>
              </div>
            </div>
            <div className="rooms-container-item-top-right-bottom-left">
              <div className="rooms-container-item-top-right-bottom-left-top">
                <p>Camere disponibile:</p>
              </div>
              <div className="rooms-container-item-top-right-bottom-left-bot">
                <img src="/icons/bed.png" />
                <p>
                  {calculateMinimumDisponibility(roomItem, aval, dateRange) == 1
                    ? "O cameră disponibilă"
                    : `${calculateMinimumDisponibility(roomItem, aval, dateRange)} camere`}
                </p>
              </div>
            </div>
            <div className="rooms-container-item-top-right-bottom-prices">
              {aval == undefined || rates == undefined ? (
                <p>Incărcare calendar prețuri...</p>
              ) : (
                <CalendarWithPrice
                  buttonText={
                    calculateMinimumDisponibility(roomItem, aval, dateRange) == 0
                      ? "Schimbă interval"
                      : "Vezi tarifele zilelor"
                  }
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  rates={rates !== undefined ? rates[roomItem.ratesId] : undefined}
                  aval={aval !== undefined ? aval[roomItem.channexRoomId] : undefined}
                  handleButtonSearchRooms={handleButtonSearchRooms}
                />
              )}
            </div>
          </div>
          {calculateMinimumDisponibility(roomItem, aval, dateRange) == 0 ? (
            <div className="rooms-container-item-top-unavailable">
              <p>Nu sunt disponibile camere in acest interval</p>
              <img src="icons/unavailable.png" />
            </div>
          ) : (
            <div className="rooms-container-item-top-right-packages">
              {roomItem.packages.map((packItem, index) => {
                return (
                  <div key={index} className="rooms-container-item-top-right-packages-pack">
                    <div className="rooms-container-item-top-right-packages-pack-left">
                      <div className="rooms-container-item-top-right-packages-pack-left-top">
                        <p>{packItem.name}</p>{" "}
                        {packItem.priceAddon === 0 ? (
                          <span>(fară adaos)</span>
                        ) : (
                          <span>(+{packItem.priceAddon} Ron / Zi)</span>
                        )}
                      </div>
                      <div className="rooms-container-item-top-right-packages-pack-left-bot">
                        <p>{packItem.type == "refundable" ? "Rambursabilă" : "Nerambursabilă"}</p>
                      </div>
                    </div>
                    <div className="rooms-container-item-top-right-packages-pack-center">
                      <div className="rooms-container-item-top-right-packages-pack-center-top">
                        <p>
                          Total pentru{" "}
                          {extractDateRangeNumber(dateRange) == 1
                            ? "o noapte"
                            : `${extractDateRangeNumber(dateRange)} nopți`}
                          :
                        </p>
                      </div>
                      <div className="rooms-container-item-top-right-packages-pack-center-bot">
                        <p>
                          {calculatePriceForSelectedDays(roomItem, dateRange, rates, packItem)} RON
                        </p>
                      </div>
                    </div>
                    <div className="rooms-container-item-top-right-packages-pack-right">
                      <Button
                        text="Rezervă"
                        onClick={() =>
                          handleAddToCheckout(
                            packItem,
                            calculatePriceForSelectedDays(roomItem, dateRange, rates, packItem)
                          )
                        }
                        icon={undefined}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./Style/TopBar.css";
import { SERVER_ADDR } from "../../config";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

export default function TopBar({ hotelId, hotelData }) {
  const [user, setUser] = useState(null);
  const [rootLink, setRootLink] = useState("");

  const [isUserValid, setIsUserValid] = useState(false);

  const checkToken = async () => {
    const myToken = Cookies.get("jwt");
    if (myToken == undefined) return;

    try {
      const { data } = await axios.get(`${SERVER_ADDR}/api/token`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
        },
      });

      setUser(data.user);
    } catch (err) {
      console.log("err", err);
    }
  };

  const checkIfAdminAndOwnThisProperty = () => {
    if (user == null) return false;
    if (user.role === "admin" && user.managedHotel === hotelId) {
      setIsUserValid(true);
    }
  };

  const logoutHandler = () => {
    Cookies.remove("jwt");
    window.location.reload();
  };

  const extractRootLink = () => {
    let extractedRootLink = `/rezerva?hotel=${hotelData._id}`;
    setRootLink(extractedRootLink);
  };

  useEffect(() => {
    checkIfAdminAndOwnThisProperty();
  }, [user]);

  useEffect(() => {
    checkToken();
    extractRootLink();
  }, []);

  const [openConfirm, setOpenConfirm] = useState(false);
  const handleCloseConfirm = () => setOpenConfirm(false);
  const handleOpenConfirm = () => setOpenConfirm(true);

  return (
    <div className="topbar-container-wrap">
      {isUserValid === true ? (
        <div className="topbar-container-admin">
          <div className="topbar-container-admin-left">
            <img src="/icons/danger.png" />
            <p>
              Ești logat ca administrator. Ai posibilitatea de a crea o rezervare, sărind pasul de
              plată online (în cazul rezervărilor ce vor fi plătite fizic la locație).
            </p>
            <img src="/icons/danger.png" />
          </div>
          <div className="topbar-container-admin-right">
            <Link to="/admin">Înapoi la dashboard</Link>
            <Dialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Ești sigur ca dorești să te deloghezi?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleCloseConfirm}>Renunță</Button>
                <Button
                  onClick={async () => {
                    logoutHandler();
                  }}
                  autoFocus
                >
                  Delogare
                </Button>
              </DialogActions>
            </Dialog>

            <button onClick={setOpenConfirm}>Logout</button>
          </div>
        </div>
      ) : null}

      <div
        style={{
          background: hotelData.landingCss.baseColor,
          borderBottom: `2px solid ${hotelData.landingCss.secondaryColor}`,
        }}
        className="topbar-container"
      >
        <div className="topbar-container-left">
          <Link to={rootLink}>
            <img src={hotelData.landingCss.logoUrl} />
            <div className="topbar-container-left-core">
              <p>Rezervă camere</p>
              <p>
                <b>{hotelData.details.name}</b>
              </p>
            </div>
          </Link>
        </div>
        <div className="topbar-container-right">
          <div className="topbar-container-right-top">
            <Link to="/login">Login</Link>
          </div>
          <div className="topbar-container-right-bot">
            <Link to="https://magnusweb.ro/">
              <span>powered by </span> <p>MagnusBooking</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

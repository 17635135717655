import axios from "axios";
import Cookies from "js-cookie";
import "./Style/EditProperty.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Select from "@mui/material/Select";
import TabContext from "@mui/lab/TabContext";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";

import { setup, landingCss, srlDetails, details, simulateAPISource } from "./AddProperty/config.js";

import { KeyValueEditor, ArrayTypeEditor, ComplexRoomEditor } from "./AddProperty/Editors.js";
import { SERVER_ADDR } from "../../config.js";

export default function EditProperty() {
  // Material UI Tabs
  const [tabValue, setTabValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [hotels, setHotels] = useState([]); //all the hotels in the app, fetched from the API

  //if selected is keept on null, keeps the page hidden until a hotel is selected to be updated
  const [selected, setSelected] = useState(); //current selected hotel to be updated

  // Data bucket
  // Contain all the hotel data divided in 3 buckets: data (basic fiels, photos:Array<string>, rooms)
  const [data, setData] = useState({});
  const [photos, setPhotos] = useState([]);
  const [rooms, setRooms] = useState([]);

  const [okToRender, setOkToRender] = useState(false);

  const initStateFromSelectedHotel = () => {
    // Extract data hotel data from API
    let apiData = { ...hotels[selected] };
    delete apiData["photos"];
    delete apiData["rooms"];

    let apiPhotos = [...hotels[selected]["photos"]];
    let apiRooms = [...hotels[selected]["rooms"]];

    // Introduce hotel data into editor
    setData(apiData);
    setPhotos(apiPhotos);
    setRooms(apiRooms);
    setOkToRender(true);
  };

  const getHotels = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(`${SERVER_ADDR}/api/property/`, config);

      setHotels(data);
    } catch (err) {
      console.log("can't get properties:", err);
    }
  };

  useEffect(() => {
    getHotels();
  }, []);

  useEffect(() => {
    if (selected !== undefined) {
      initStateFromSelectedHotel();
    }
  }, [selected]);

  const handleUpdate = async () => {
    // Concat all the data from the editor and send it to the API to update the selected hotel
    const finalHotelObj = { ...data };
    finalHotelObj["photos"] = [...photos];
    finalHotelObj["rooms"] = [...rooms];

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
          "Content-Type": "application/json",
        },
      };

      const idToUpdate = hotels[selected]["_id"];
      const prepareBody = { hotel: finalHotelObj };

      const { data } = await axios.put(
        `${SERVER_ADDR}/api/property/${idToUpdate}`,
        { ...finalHotelObj },
        config
      );
      window.alert("Property updated!");
      window.location.reload();
    } catch (err) {
      console.log("can't update property:", err);
    }
  };

  const handleDelete = async () => {
    // Delete selected hotel from the API
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
          "Content-Type": "application/json",
        },
      };
      const idToDelete = hotels[selected]["_id"];
      const { data } = await axios.delete(`${SERVER_ADDR}/api/property/${idToDelete}`, config);
      window.alert("Property deleted!");
      window.location.reload();
    } catch (err) {
      console.log("can't del property:", err);
    }
  };

  return (
    <>
      <p>select a hotel for edit:</p>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selected}
        label="Age"
        onChange={(e) => setSelected(e.target.value)}
      >
        {hotels.map((el, index) => {
          return (
            <MenuItem key={index} value={index}>
              {el.details.name}
            </MenuItem>
          );
        })}
      </Select>
      {okToRender == false ? (
        <p>Please select a hotel to pe updated</p>
      ) : (
        <Box sx={{ width: "100%", typography: "body1" }}>
          <button className="sudo-btn-action" onClick={handleUpdate}>
            Update
          </button>
          <button className="sudo-btn-action" onClick={handleDelete}>
            Delete
          </button>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Hotel Setup" value="1" />
                <Tab label="Landing Css" value="2" />
                <Tab label="Srl Details" value="3" />
                <Tab label="Hotel Details" value="4" />
                <Tab label="Photos" value="5" />
                <Tab label="Rooms" value="6" />
              </TabList>
            </Box>

            {/* Type 1 editor */}
            <TabPanel value="1">
              <KeyValueEditor objSource={setup} dataKey={"setup"} data={data} setData={setData} />
            </TabPanel>
            <TabPanel value="2">
              <KeyValueEditor
                objSource={landingCss}
                dataKey={"landingCss"}
                data={data}
                setData={setData}
              />
            </TabPanel>
            <TabPanel value="3">
              <KeyValueEditor
                objSource={srlDetails}
                dataKey={"srlDetails"}
                data={data}
                setData={setData}
              />
            </TabPanel>
            <TabPanel value="4">
              <KeyValueEditor
                objSource={details}
                dataKey={"details"}
                data={data}
                setData={setData}
              />
            </TabPanel>

            {/* Type 2 editor */}
            <TabPanel value="5">
              <ArrayTypeEditor photos={photos} setPhotos={setPhotos} />
            </TabPanel>

            {/* Type 3 editor */}
            <TabPanel value="6">
              <ComplexRoomEditor rooms={rooms} setRooms={setRooms} />
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </>
  );
}

import "./Button.css";
import React from "react";
import Button from "@mui/material/Button";

export default function CustomButton({
  text,
  onClick,
  icon,
  color,
  disabled = false,
  backgroundColor,
}) {
  const buttonStyle = {
    backgroundColor: backgroundColor
      ? backgroundColor
      : `${disabled === false ? "var(--baseline-color)" : "var(--secondary-color)"}`,
    color: "white",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  };

  return (
    <Button
      variant="contained"
      onClick={onClick}
      startIcon={
        icon ? <img src={icon} alt="icon" style={{ height: "30px", objectFit: "contain" }} /> : null
      }
      style={buttonStyle}
      disabled={disabled}
      className="custom-button"
    >
      <span style={{ fontSize: "0.8rem" }}> {text}</span>
    </Button>
  );
}

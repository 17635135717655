import { useState, useEffect } from "react";
import axios from "axios";
import "./Style/MyAccount.css";
import { SERVER_ADDR } from "../../config";

export default function Account({ user }) {
  const [hotelData, setHotelData] = useState(undefined);

  useEffect(() => {
    if (user !== undefined) {
      fetchHotelData(user.managedHotel);
    }
  }, [user]);

  const fetchHotelData = async (hotelIdSrc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(`${SERVER_ADDR}/api/property/${hotelIdSrc}`, config);
      setHotelData(data);
    } catch (err) {
      window.alert("can't fetch this hotel data");
      console.log("can't ftch this hotel id:", err);
    }
  };

  function capitalizeFirstLetter(str) {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  return (
    <div className="account-container">
      <div className="account-container-top">
        <div className="account-container-top-bg"></div>
        <div className="account-container-top-profile">
          <div className="account-container-top-profile-core">
            <div className="account-container-top-profile-core-img">
              <img src="/adminDash/fakeProfile.png" alt="profile" />
            </div>
            <div className="account-container-top-profile-core-text">
              <h4>{user.email}</h4>
              <p>{user.email}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="account-container-bot">
        <div className="account-container-bot-core">
          <div className="account-container-bot-core-header">
            <p>Informații profil</p>
          </div>
          <div className="account-container-bot-core-rows">
            <div className="account-container-bot-core-rows-el">
              <h4>Nume:</h4>
              <p>{user.name}</p>
            </div>
            <div className="account-container-bot-core-rows-el">
              <h4>Email:</h4>
              <p>{user.email}</p>
            </div>
            <div className="account-container-bot-core-rows-el">
              <h4>Rol:</h4>
              <p>{user.role}</p>
            </div>
          </div>
        </div>
        <div className="account-container-bot-core">
          <div className="account-container-bot-core-header">
            <p>Informații hotel:</p>
          </div>
          <div className="account-container-bot-core-rows">
            {hotelData !== undefined
              ? Object.keys(hotelData.details).map((el) => {
                  return (
                    <div className="account-container-bot-core-rows-el">
                      <h4>{capitalizeFirstLetter(el)}:</h4>
                      <p>{hotelData.details[el]}</p>
                    </div>
                  );
                })
              : null}
            <div className="account-container-bot-core-rows-el">
              <h4>Nume:</h4>
              <p>{user.name}</p>
            </div>
            <div className="account-container-bot-core-rows-el">
              <h4>Email:</h4>
              <p>{user.email}</p>
            </div>
            <div className="account-container-bot-core-rows-el">
              <h4>Rol:</h4>
              <p>{user.role}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./Style/Landing.css";
import Rooms from "../../components/Landing/Rooms";
import TopBar from "../../components/Landing/TopBar";
import Checkout from "../../components/Landing/Checkout";
import HotelDetails from "../../components/Landing/HotelDetails";
import DateRangeSelector from "../../components/Landing/DateRangeSelector";
import {
  extractPriceFromCheckout,
  extractRoomPricesPerDay,
  formatDateToYYYYMMDDString,
  parseStringYYYYMMDDtoDate,
  setQueryParam,
} from "../../utils/functions";
import Footer from "../../components/Landing/Footer";
import CircularLoading from "../../atoms/CircularLoading/CircularLoading";
import { SERVER_ADDR } from "../../config";

export default function Landing() {
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [hotelId, setHotelId] = useState(); //query parameter
  const [hotelData, setHotelData] = useState();

  const [aval, setAval] = useState(); //channex data (using hotelId)
  const [rates, setRates] = useState(); //channex data (using hotelId)
  const [dateRange, setDateRange] = useState(); //date range widget [start, end] (Date type)

  const [checkout, setCheckout] = useState([]);
  const [totalPriceCheckout, setTotalPriceCheckout] = useState(null);
  const [roomsPricesPerDay, setRoomsPricesPerDay] = useState(null);

  useEffect(() => {
    if (dateRange !== undefined && rates !== undefined) {
      let extractedPrice = extractPriceFromCheckout(checkout, rates, dateRange);
      setTotalPriceCheckout(extractedPrice);

      // used in admin panel for reservation updates
      let roomsPricesPerDay = extractRoomPricesPerDay(checkout, rates, dateRange);
      setRoomsPricesPerDay(roomsPricesPerDay);
    }
  }, [checkout, dateRange, rates]); //update total price in checkout & room prices per day (used in checkout)

  useEffect(() => {
    //reset current checkout items when date range was updated
    setCheckout([]);
  }, [dateRange]);

  useEffect(() => {
    const fetchHotelData = async () => {
      setLoading(true);
      if (hotelId) {
        fetchHotel(hotelId);

        // extract dateRange from query params (use default rane if failed (empty of failed to parse))
        let firstDateRange = extractDateRange();
        await fetchAvailability(firstDateRange, hotelData);
        await fetchRates(firstDateRange, hotelData);
        setLoading(false);
      }
    };
    
    fetchHotelData();
    
  }, [hotelId]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const hotel = queryParams.get("hotel");
    if (hotel == null || hotel == undefined) {
      window.alert("Wrong hotel id!");
      window.location.href = "/fallback";
    } else {
      setHotelId(hotel);
    }
  }, []);

  const handleButtonSearchRooms = async (newDateRange) => {
    setLoading(true);
    await fetchAvailability(newDateRange, hotelData);
    await fetchRates(newDateRange, hotelData);
    setLoading(false);
  };

  // Init page functions
  const fetchHotel = async (hotelId) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json", // you don't need auth for public route
        },
      };

      const { data } = await axios.get(`${SERVER_ADDR}/api/property/${hotelId}`, config);
      setHotelData(data);
    } catch (err) {
      // window.alert("can't fetch this hotel id");
      console.log("can't fetch this hotel id:", err);
    }
  };

  const extractDateRange = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const startParam = queryParams.get("startDate");
    const endParam = queryParams.get("endDate");

    const defaultStartDate = new Date(new Date().getTime() + 86400 * 1000);

    const defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate() + 7);

    if (startParam == null || endParam == null) {
      //default date range
      setDateRange([defaultStartDate, defaultEndDate]);

      //add into query params the new default range
      setQueryParam("startDate", formatDateToYYYYMMDDString(defaultStartDate));
      setQueryParam("endDate", formatDateToYYYYMMDDString(defaultEndDate));
      return [defaultStartDate, defaultEndDate];
    } else {
      //extract from query param date ranges //try to parse
      const parsedStartDate = parseStringYYYYMMDDtoDate(startParam);
      const parsedEndDate = parseStringYYYYMMDDtoDate(endParam);

      if (parsedStartDate == null || parsedEndDate == null) {
        //failed to parse, will use default date range
        setDateRange([defaultStartDate, defaultEndDate]);

        //add into query params the new default range
        setQueryParam("startDate", formatDateToYYYYMMDDString(defaultStartDate));
        setQueryParam("endDate", formatDateToYYYYMMDDString(defaultEndDate));

        return [defaultStartDate, defaultEndDate];
      } else {
        // date rage is valid date, but they need to start from tommorow
        // move to utils
        function resetTimeToMidnight(inputDate) {
          if (!(inputDate instanceof Date) || isNaN(inputDate)) {
            // Verifică dacă inputul este un obiect Date valid
            throw new Error("Inputul nu este un obiect Date valid.");
          }

          // Resetează ora, minutul și secunda la 00:00:00
          inputDate.setHours(0, 0, 0, 0);

          return inputDate;
        }

        // verifica ca ambele sa fie >= tommorow
        let tommorow = new Date(new Date().getTime() + 86400 * 1000);
        tommorow = resetTimeToMidnight(tommorow);

        if (
          parsedStartDate.getTime() < tommorow.getTime() ||
          parsedEndDate.getTime() < tommorow.getTime()
        ) {
          window.alert("Intervalul trebuie sa inceapa strict de maine");
          setDateRange([tommorow, tommorow]);
          setQueryParam("startDate", formatDateToYYYYMMDDString(tommorow));
          setQueryParam("endDate", formatDateToYYYYMMDDString(tommorow));
          window.location.reload();
        }
        // verifica ca datele sa fie consecutive (startDate apoi endDate) in acesta ordine strict
        else if (parsedStartDate.getTime() > parsedEndDate.getTime()) {
          window.alert("Intervalul are endDate inainte lui startDate");
          setDateRange([tommorow, tommorow]);

          setQueryParam("startDate", formatDateToYYYYMMDDString(tommorow));
          setQueryParam("endDate", formatDateToYYYYMMDDString(tommorow));
          window.location.reload();
        } else {
          // CASE OK
          setDateRange([parsedStartDate, parsedEndDate]);
        }

        return [parsedStartDate, parsedEndDate];
      }
    }
  };

  const fetchAvailability = async (dateRangeSource, hotelDataSource) => {
    let dayInMiliseconds = 86400 * 1000;
    let nrDaysOffset = 60;

    // let startDateObj = dateRangeSource[0];
    let startDateObj = new Date();
    let endDateObj = new Date(dateRangeSource[1].getTime() + dayInMiliseconds * nrDaysOffset);

    const startDateQuery = formatDateToYYYYMMDDString(startDateObj);
    const endDateQuery = formatDateToYYYYMMDDString(endDateObj);

    try {
      const config = {
        headers: {
          // you don't need auth for public route
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${SERVER_ADDR}/api/property/${hotelId}/availability?startDate=${startDateQuery}&endDate=${endDateQuery}`,
        config
      );
      setAval(data);
    } catch (err) {
      window.alert("can't fetch this hotel aval");
      console.log("can't fetch this hotel aval:", err);
    }
  };

  const fetchRates = async (dateRangeSource, hotelDataSource) => {
    // From here you can setup how far the rates are displayed in "Vezi tarife pe zile" calendar
    let dayInMiliseconds = 86400 * 1000;
    let nrDaysOffset = 60;

    // let startDateObj = dateRangeSource[0];
    let startDateObj = new Date();
    let endDateObj = new Date(dateRangeSource[1].getTime() + dayInMiliseconds * nrDaysOffset);

    const startDateQuery = formatDateToYYYYMMDDString(startDateObj);
    const endDateQuery = formatDateToYYYYMMDDString(endDateObj);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${SERVER_ADDR}/api/property/${hotelId}/rates?startDate=${startDateQuery}&endDate=${endDateQuery}`,
        config
      );

      setRates(data);
    } catch (err) {
      window.alert("can't fetch this hotel aval");
      console.log("can't fetch this hotel rates:", err);
    }
  };

  const addToCheckout = (paramSelectedPackage, roomItem, priceEntryPoint) => {
    //attach all types of facilities (checkbox + interval) & extra adults/childs logic
    const channexRoomId = roomItem.channexRoomId;
    const ratesId = roomItem.ratesId;

    const checkboxFacilities = roomItem.checkboxFacilities.map((el) => {
      let copy = { ...el };
      copy.selected = false;
      return copy;
    });

    const intervalFacilities = roomItem.intervalFacilities.map((el) => {
      let copy = { ...el };
      copy.selectedValue = 0;
      return copy;
    });

    const extraUsers = {
      extraAdultPrice: roomItem.extraAdultPrice,
      extraChildPrice: roomItem.extraChildPrice,
      extraChildRatio: roomItem.extraChildRatio,
      capacity: roomItem.capacity,
      maxCapacity: roomItem.maxCapacity,
      // extra field for future selection logic
      selectedAdults: 0,
      selectedChilds: 0,
    };

    const newCheckoutItem = {
      roomDetailsForCheckout: {
        name: roomItem.name,
      },
      channexRoomId: channexRoomId,
      ratesId: ratesId,
      selectedPackage: paramSelectedPackage,
      checkboxFacilities: checkboxFacilities,
      intervalFacilities: intervalFacilities,
      extraUsers: extraUsers,
      priceEntryPoint: priceEntryPoint,
    };
    setCheckout((prev) => {
      let copy = [...prev];
      copy.push(newCheckoutItem);
      return copy;
    });
  };

  const deleteFromChekout = (indexToDelete) => {
    setCheckout((prev) => {
      let copy = [...prev];
      copy.splice(indexToDelete, 1);
      return copy;
    });
  };

  return (
    <>
      {hotelData == undefined ? (
        <p>Loading</p>
      ) : (
        <div
          // Init global app css variables
          style={{
            "--baseline-color": hotelData.landingCss.baseColor,
            "--secondary-color": hotelData.landingCss.secondaryColor,
          }}
          className="landing-container"
        >
          <TopBar hotelId={hotelId} hotelData={hotelData} />
          <>
            {loading == true ? (
              <div className="landing-circular-loading">
                <CircularLoading />
              </div>
            ) : (
              <>
                <div className="landing-container-cols">
                  <div className="landing-container-left-col">
                    <DateRangeSelector
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      handleButtonSearchRooms={handleButtonSearchRooms}
                    />
                    <Rooms
                      hotelData={hotelData}
                      aval={aval}
                      rates={rates}
                      checkout={checkout}
                      addToCheckout={addToCheckout}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      handleButtonSearchRooms={handleButtonSearchRooms}
                    />
                  </div>
                  <div className="landing-container-left-right-col">
                    <Checkout
                      checkout={checkout}
                      hotelData={hotelData}
                      hotelId={hotelId}
                      dateRange={dateRange}
                      setCheckout={setCheckout}
                      deleteFromChekout={deleteFromChekout}
                      totalPriceCheckout={totalPriceCheckout}
                      roomsPricesPerDay={roomsPricesPerDay}
                      rates={rates}
                    />
                  </div>
                </div>
                <HotelDetails hotelData={hotelData} />
                <Footer hotelData={hotelData} />
              </>
            )}
          </>
        </div>
      )}
    </>
  );
}

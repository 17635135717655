import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_ADDR } from "../../config";
import Cookies from "js-cookie";
import "./Style/ReceiptIndexes.css";
import TextField from "@mui/material/TextField";

export default function ReceiptIndexes({ user }) {
  const [currentIndexes, setCurrentIndexes] = useState(null);
  const [hotelId, setHotelId] = useState(null);
  const [newSeriesInput, setNewSeriesInput] = useState("");

  useEffect(() => {
    let hotelId = user["managedHotel"];
    setHotelId(hotelId);
  }, [user]);

  useEffect(() => {
    fetchHotelReceiptIndex(hotelId);
  }, [hotelId]);

  const fetchHotelReceiptIndex = async (hotelIdParam) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
          "Content-Type": "application/json",
        },
      };

      let receiptIndexesResp = await axios.get(
        `${SERVER_ADDR}/api/receipt-index/${hotelIdParam}`,
        config
      );

      setCurrentIndexes(receiptIndexesResp.data);
    } catch (err) {
      console.log("Err fetching receipt indexes:", err);
      window.alert("Eroare la obtinerea seriilor facturilor!");
    }
  };

  const handleInputChange = (e) => {
    setNewSeriesInput(e.target.value);
  };

  const handleNewSeriesSubmit = async (who) => {
    if (who.trim() === "") {
      window.alert("Numele seriei nu poate sa fie gol!");
      return;
    } else {
      //TODO: axios request to add and switch to the new series
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt")}`,
            "Content-Type": "application/json",
          },
        };
        await axios.post(
          `${SERVER_ADDR}/api/receipt-index`,
          {
            id: hotelId,
            newSeries: who,
          },
          config
        );
        fetchHotelReceiptIndex(hotelId);
      } catch (err) {
        window.alert("Eroare la adaugarea unei serii noi de facturi!");
      }

      setNewSeriesInput("");
    }
  };

  return (
    <div className="receipt-indexes-container">
      <div className="receipt-indexes-container-top">
        <TextField
          id="outlined-basic"
          label="Adauga serie noua"
          variant="outlined"
          name="serie"
          sx={{ width: "100%" }}
          value={newSeriesInput}
          onChange={handleInputChange}
          required
        />
        <button
          onClick={() => {
            handleNewSeriesSubmit(newSeriesInput);
          }}
        >
          Adaugă
        </button>
      </div>
      <div className="receipt-indexes-container-bot">
        {!currentIndexes ? (
          <p>Loading...</p>
        ) : (
          <>
            {Object.keys(currentIndexes["seriesHistory"]).map((elSeries) => {
              return (
                <div className="receipt-index-el">
                  <p>
                    {elSeries} - <b>#{currentIndexes["seriesHistory"][elSeries]}</b>
                  </p>
                  {elSeries === currentIndexes["activeSeries"] ? (
                    <p style={{ color: "green" }}>ACTIVĂ</p>
                  ) : (
                    <div style={{ color: "red" }} className="receipt-index-el-inactive">
                      <p>INACTIVĂ</p>
                      <button
                        onClick={() => {
                          handleNewSeriesSubmit(elSeries);
                        }}
                      >
                        Activează
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

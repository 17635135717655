import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SERVER_ADDR } from "../../config";

export default function CreateAccount() {
  const [userData, setUserData] = useState({
    // username: "",
    name: "",
    password: "",
    email: "",
  });
  const [hotels, setHotels] = useState(null);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const getHotels = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(`${SERVER_ADDR}/api/property/`, config);

      setHotels(data);
    } catch (err) {
      console.log("can't get hotels:", err);
    }
  };
  useEffect(() => {
    getHotels();
  }, []);

  const handleFieldUpdate = (e) => {
    setUserData((prev) => {
      let copy = { ...prev };
      copy[e.target.name] = e.target.value;
      return copy;
    });
  };

  const handleSubmit = async () => {
    if (
      // userData.username == "" ||
      userData.name == "" ||
      userData.password == "" ||
      userData.email == "" ||
      selectedHotel == null
    ) {
      window.alert("Please complete all fields");
    } else {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt")}`,
            "Content-Type": "application/json",
          },
        };
        const { data } = await axios.post(
          `${SERVER_ADDR}/api/user/`,
          {
            ...userData,
            managedHotel: hotels[selectedHotel]["_id"],
          },
          config
        );
        window.alert("User created!");
        window.location.reload();
      } catch (err) {
        if (err.response.data.errors !== undefined) {
          err.response.data.errors.map((el) => {
            window.alert(el);
          });
        } else {
          console.log("err:", err);
          window.alert("A aparut o eroare!");
        }
      }
    }
  };

  return (
    <>
      <p>Create Account</p>
      <TextField
        id="outlined-basic"
        label="email"
        name="email"
        onChange={handleFieldUpdate}
        value={userData["email"]}
        sx={{ marginRight: "10px" }}
      />
      <TextField
        id="outlined-basic"
        label="name"
        name="name"
        onChange={handleFieldUpdate}
        value={userData["name"]}
        sx={{ marginRight: "10px" }}
      />
      <TextField
        id="outlined-basic"
        label="password"
        name="password"
        onChange={handleFieldUpdate}
        value={userData["password"]}
        sx={{ marginRight: "10px" }}
      />
      {hotels == null ? (
        <p>x</p>
      ) : (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedHotel}
          label="Age"
          onChange={(e) => setSelectedHotel(e.target.value)}
        >
          {hotels.map((el, index) => {
            return (
              <MenuItem key={index} value={index}>
                {el.details.name}
              </MenuItem>
            );
          })}
        </Select>
      )}

      <button className="sudo-btn-action" onClick={handleSubmit}>
        Create new account
      </button>
    </>
  );
}

import React, { useState, useEffect } from "react";
import "./Style/Reservations.css";
import { Link } from "react-router-dom";

import axios from "axios";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Input,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Cookies from "js-cookie";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "react-datepicker/dist/react-datepicker.css";
import ModalReservationUpdate from "../../components/Dashboard/Modals/ModalReservationUpdate/ModalReservationUpdate";
import { SERVER_ADDR } from "../../config";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DateRangeSelectorAdmin from "../../components/Dashboard/DateRangeSelectorAdmin";
import { formatDateToYYYYMMDDString } from "../../utils/functions";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const style = {
  headerCell: {
    fontWeight: "bold",
    textAlign: "center",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    outline: "none",
  },
};

export default function Reservations({ user }) {
  const [hotelId, setHotelId] = useState(null);

  const [frontEndQuery, setFrontEndQuery] = useState("");

  const applyQuery = (items, query) => {
    if (query === "" || query === undefined) {
      return items;
    } else {
      return items.filter((obj) => obj["contorIndexFactura"].includes(query));
    }
  };
  const extractReceiptIndexesValue = (reservationsParam) => {
    let values = [];
    reservationsParam.forEach((el) => {
      values.push(el["contorIndexFactura"]);
    });
    return values;
  };

  useEffect(() => {
    setHotelId(user["managedHotel"]);
  }, [user]);

  const headers = [
    "Făcut la",
    "Status Plată",
    "Adaugă",
    "Șterge",
    "Modifică",
    "Anulează",
    "Factură",
    "Pagina factură",
    "Index factură",
  ];

  const [reservations, setReservations] = useState([]);

  const [expandedRow, setExpandedRow] = useState(null); //index of reservations
  const [modalIndex, setModalIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const [modalMode, setModalMode] = useState(0);

  // 0 - no rules (use for update for both online and fizic)
  // 1 - only delete (for online)
  // 2 - only add (for online)

  const fetchReservations = async (startDate, endDate) => {
    if (hotelId === null) return;
    try {
      let reservationsResp = await axios.get(
        `${SERVER_ADDR}/api/reservation?hotelId=${hotelId}&startDate=${startDate}&endDate=${endDate}`
      );
      setReservations(reservationsResp.data);
    } catch (err) {
      window.alert("Nu pot prelua rezervari!");
    }
  };

  const handleExpand = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  const refundReservation = async (reservationSrc) => {
    let id = reservationSrc._id; //reservation id

    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("jwt")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      await axios.get(`${SERVER_ADDR}/api/reservation/${id}/transaction/refund`, config);
      window.alert("Rezervare refunded!");
      window.location.reload();
    } catch (err) {
      console.log("Can't cancel reservation:", err);
      window.alert("Nu pot anula rezervarea!");
    }
  };

  const handleDeletePartialItems = async (index) => {
    setModalIndex(index);
    setModalMode(1);
    setOpen(true);
  };

  // Don't delete this function (incoming feature)
  const handleAddPartialItems = async (index) => {
    setModalIndex(index);
    setModalMode(2);
    setOpen(true);
  };

  const checkIfPartialRefundIsAvailable = (reservationSrc) => {
    let updates = reservationSrc.updates;
    if (updates) {
      let parsedUpdates = JSON.parse(updates);
      return parsedUpdates.deleted && parsedUpdates.deleted.length > 0;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (hotelId !== null) {
      const dateInit = initDates();
      const startDateInitTimeStamp = formatDateToYYYYMMDDString(dateInit[0]);
      const endDateInitTimeStamp = formatDateToYYYYMMDDString(dateInit[1]);

      fetchReservations(startDateInitTimeStamp, endDateInitTimeStamp);
    }
  }, [hotelId]);

  const CustomTableCell = ({ children }) => {
    return (
      <TableCell>
        <div className="reservations-container-custom-cell">{children}</div>
      </TableCell>
    );
  };

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const [clickedDeleteReservation, setClickedDeleteReservation] = useState(null);

  function initDates() {
    const currentDate = new Date();

    const pastDate = new Date();
    pastDate.setDate(currentDate.getDate() - 30);

    const futureDate = new Date();
    futureDate.setDate(currentDate.getDate() + 30);

    return [pastDate, futureDate];
  }

  const [localDateRange, setLocalDateRange] = useState(initDates);

  const refreshFetchReservations = () => {
    const startDateTimeStamp = formatDateToYYYYMMDDString(localDateRange[0]);
    const endDateTimeStamp = formatDateToYYYYMMDDString(localDateRange[1]);

    fetchReservations(startDateTimeStamp, endDateTimeStamp);
  };

  return (
    <>
      <React.Fragment>
        <Dialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Ești sigur ca dorești sa anulezi această rezervare?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Această acțiune este ireversibilă și implică eliberarea camrelor rezervare, dar și
              returnarea banilor clintului (în cazul rezervarilor plătitle online).
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirm}>Renunță</Button>
            <Button
              onClick={async () => {
                await refundReservation(clickedDeleteReservation);
                handleCloseConfirm();
              }}
              autoFocus
            >
              Continuă
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <div className="reservations-wrapper">
        <div className="reservations-wrapper-date">
          <DateRangeSelectorAdmin
            localDateRange={localDateRange}
            setLocalDateRange={setLocalDateRange}
            btnAction={refreshFetchReservations}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={extractReceiptIndexesValue(reservations)}
            sx={{ width: 300, marginBottom: "20px" }}
            onChange={(e) => {
              setFrontEndQuery(e.target.value);
            }}
            renderInput={(params) => <TextField {...params} label="Caută dupa factură" />}
          />
        </div>
        <div className="reservations-wrapper-content">
          {reservations.length === 0 ? (
            <p>Loading...</p>
          ) : (
            <>
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style.modal}>
                  <ModalReservationUpdate
                    currentReservation={reservations[modalIndex]}
                    closeModal={() => {
                      setOpen(false);
                    }}
                    modalMode={modalMode}
                  />
                </Box>
              </Modal>

              <div className="reservations-container">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        {headers.map((el, key) => {
                          return (
                            <TableCell key={key} sx={style.headerCell}>
                              {el}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {applyQuery(reservations, frontEndQuery).map((el, index) => {
                        return (
                          <>
                            <TableRow
                              key={index}
                              sx={{
                                borderLeft: "2px solid #023047",
                                borderRight: "2px solid #023047",
                              }}
                            >
                              <TableCell>
                                <Button
                                  onClick={() => handleExpand(index)}
                                  startIcon={<ExpandMoreIcon />}
                                ></Button>
                              </TableCell>

                              <CustomTableCell>{<span>{el.createdAt}</span>}</CustomTableCell>
                              <CustomTableCell>
                                {
                                  <span>
                                    <b>{el.paymentMethod}</b>
                                    <br></br>[{el.paymentStatus}]
                                  </span>
                                }
                              </CustomTableCell>

                              <CustomTableCell>
                                <span>Indisponibil</span>
                              </CustomTableCell>

                              <CustomTableCell>
                                {el.paymentStatus === "refunded" ? (
                                  <p>-</p>
                                ) : (
                                  <button onClick={() => handleDeletePartialItems(index)}>
                                    Elimină
                                  </button>
                                )}

                                {checkIfPartialRefundIsAvailable(el) ? (
                                  <a
                                    href={`${SERVER_ADDR}/api/reservation/${el._id}/receipt-pdf-cancels`}
                                    download
                                  >
                                    Descarcă stornăre
                                  </a>
                                ) : null}
                              </CustomTableCell>

                              <CustomTableCell sx={style.cell}>
                                {el.paymentStatus === "refunded" ? (
                                  <p>-</p>
                                ) : (
                                  <button
                                    onClick={() => {
                                      setModalMode(0);
                                      setOpen(true);
                                      setModalIndex(index);
                                    }}
                                  >
                                    Modifică
                                  </button>
                                )}
                              </CustomTableCell>

                              <CustomTableCell sx={style.cell}>
                                {el.paymentStatus === "refunded" ? (
                                  <p>-</p>
                                ) : (
                                  <button
                                    onClick={() => {
                                      setClickedDeleteReservation(el);
                                      setOpenConfirm(true);

                                      // let deleteConfirmation = window.confirm(
                                      //   "Ești sigur ca dorești să ștergi această rezervare?"
                                      // );
                                      // if (deleteConfirmation) {
                                      //   refundReservation(el);
                                      // }
                                    }}
                                  >
                                    Anulează
                                  </button>
                                )}
                              </CustomTableCell>
                              <CustomTableCell sx={style.cell}>
                                <a
                                  href={`${SERVER_ADDR}/api/reservation/${el._id}/receipt-pdf`}
                                  download
                                >
                                  Descarcă
                                </a>
                              </CustomTableCell>
                              <CustomTableCell>
                                <Link to={`/receipt?id=${el._id}`} target="_blank">
                                  Link
                                </Link>
                              </CustomTableCell>
                              <CustomTableCell sx={style.cell}>
                                <span>{el.contorIndexFactura}</span>
                              </CustomTableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={headers.length + 1}>
                                <Collapse
                                  in={expandedRow === index}
                                  sx={{ border: "2px solid megenta" }}
                                >
                                  <RoomsExtractor checkoutEl={el} />
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

const RoomsExtractor = ({ checkoutEl }) => {
  const extractUseData = (src) => {
    let obj = [
      ["Nume", src.userFields.Nume[0]],
      ["Prenume", src.userFields.Prenume[0]],
      ["Tara", src.userFields.country.label],
      ["Numar telefon", src.userFields["Numar telefon"][0]],
      ["Adresă de e-mail", src.userFields["Adresă de e-mail"][0]],
      ["Adresă", src.userFields["Adresă"][0]],
    ];
    return obj;
  };

  const extractRoomAndFacilities = (src) => {
    let items = [];

    src.checkout.forEach((el) => {
      let obj = [
        ["Camera", el.roomDetailsForCheckout.name],
        ["Pachet", el.selectedPackage.name],
        ["Returnabil", el.selectedPackage.type],
      ];

      items.push(obj);
    });

    return items;
  };

  return (
    <div className="reservations-container-el">
      <div className="reservations-container-el-left">
        <h3>Detaliile clientului:</h3>
        {extractUseData(checkoutEl).map((el) => {
          return (
            <p>
              <b>{el[0]}: </b>
              {el[1]}
            </p>
          );
        })}
      </div>
      <div className="reservations-container-el-right">
        <h3>Detaliile rezervarii:</h3>
        {extractRoomAndFacilities(checkoutEl).map((roomEl) => {
          return (
            <>
              {roomEl.map((roomElItem) => {
                return (
                  <p>
                    <b>{roomElItem[0]}: </b>
                    {roomElItem[1]}
                  </p>
                );
              })}
              <br />
            </>
          );
        })}
      </div>
    </div>
  );
};

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { roomsBaseline, packagesConfig, checkboxFacilities, intervalFacilities } from "./config";
import { getRandomColor } from "../../../utils/functions";
import "./Editors.css";

// Type 1 editor: key - value
export const KeyValueEditor = ({ objSource, dataKey, data, setData }) => {
  const handleFieldUpdate = (e) => {
    // used for basic config use cases (key - value:string)
    setData((prev) => {
      let copy = { ...prev };
      let myKey = data[dataKey];

      if (myKey == undefined) {
        // first updte of this component
        copy[dataKey] = {};
        copy[dataKey][e.target.name] = e.target.value;
      } else {
        // update the current value of this key
        copy[dataKey][e.target.name] = e.target.value;
      }
      return copy;
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {Object.keys(objSource).map((el, index) => {
        return (
          <TextField
            key={index}
            id="outlined-basic"
            label={el}
            name={el}
            onChange={handleFieldUpdate}
            value={data[dataKey][el]}
            sx={{
              marginBottom: "15px",
            }}
          />
        );
      })}
    </div>
  );
};

// Type 2 editor - array
export const ArrayTypeEditor = ({ photos, setPhotos }) => {
  // used for array like configs (simple array of values)
  const [localValue, setLocalValue] = useState("");

  const handleDelete = (index) => {
    // delete value from array
    setPhotos((prev) => {
      let copy = [...prev];
      copy.splice(index, 1);
      return copy;
    });
  };

  const handleAdd = () => {
    // add value into the array
    if (localValue !== "") {
      setPhotos((prev) => {
        let copy = [...prev];
        copy.push(localValue);
        return copy;
      });
      setLocalValue("");
    } else {
      window.alert("Please complete the input field!");
    }
  };
  return (
    <div className="editors-array-type-container">
      <div className="editors-array-type-container-add">
        <TextField
          id="outlined-basic"
          label={`Add new photo url`}
          value={localValue}
          onChange={(e) => {
            setLocalValue(e.target.value);
          }}
          sx={{
            marginRight: "20px",
          }}
        />
        <button className="sudo-btn-action" onClick={handleAdd}>
          Add new
        </button>
      </div>
      <div className="editors-array-type-container-items">
        {photos.map((el, index) => {
          return (
            <div className="editors-array-type-container-item">
              <img src={el} alt="hotel-img" title={el} />
              <button className="sudo-btn-action" onClick={() => handleDelete(index)}>
                Delete
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Type 3 editor - complex object
export const ComplexRoomEditor = ({ rooms, setRooms }) => {
  const handleAddRoom = () => {
    setRooms((prev) => {
      let copy = [...prev];

      // array like field that will be added to the complex object (spreaded into the baseline simple structure)
      let emptyObj = {
        photos: [],
        facilities: [],
        packages: [],
      };

      Object.keys(roomsBaseline).forEach((el) => {
        if (roomsBaseline[el] == "string") {
          //string
          emptyObj[el] = "";
        } else {
          // number
          emptyObj[el] = 0;
        }
      });

      copy.push({ ...emptyObj });
      return copy;
    });
  };

  const deleteRoom = (roomIndex) => {
    setRooms((prev) => {
      let copy = [...prev];
      copy.splice(roomIndex, 1);
      return copy;
    });
  };

  const handleBaselineFieldUpdate = (e, index) => {
    // extract room from array
    let extractRoom = rooms[index];

    //update room
    if (roomsBaseline[e.target.name] == "string") {
      //string
      extractRoom[e.target.name] = e.target.value;
    } else {
      //number
      extractRoom[e.target.name] = Number(e.target.value);
    }

    //insert room back into array/state
    setRooms((prev) => {
      let copy = [...prev];
      copy[index] = extractRoom;
      return copy;
    });
  };

  return (
    <div className="editors-array-type-complex-container">
      <div className="editors-array-type-complex-container-add">
        <button className="sudo-btn-action" onClick={handleAddRoom}>
          Add room
        </button>
      </div>
      <div className="editors-array-type-complex-container-items">
        <h4>Rooms</h4>
        {rooms.map((elRoom, indexRoom) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p>Room:{indexRoom.toString()}</p>
              <button
                className="sudo-btn-action"
                onClick={() => {
                  deleteRoom(indexRoom);
                }}
              >
                Delete room
              </button>
              {Object.keys(roomsBaseline).map((elField, indexField) => {
                return (
                  <TextField
                    id="outlined-basic"
                    label={elField}
                    name={elField}
                    type={roomsBaseline[elField]}
                    value={elRoom[elField]}
                    onChange={(e) => {
                      handleBaselineFieldUpdate(e, indexRoom);
                    }}
                    sx={{
                      marginBottom: "10px",
                    }}
                  />
                );
              })}
              <br />
              <br />
              {/* Photos & facilities logic */}
              <ArayEditor
                indexRoom={indexRoom}
                elRoom={elRoom}
                rooms={rooms}
                setRooms={setRooms}
                fieldKey="photos"
              />
              <ArayEditor
                indexRoom={indexRoom}
                elRoom={elRoom}
                rooms={rooms}
                setRooms={setRooms}
                fieldKey="facilities"
              />

              {/* Packages */}
              <PackagesEditor
                indexRoom={indexRoom}
                elRoom={elRoom}
                rooms={rooms}
                setRooms={setRooms}
                config={packagesConfig}
                objectKey="packages"
              />

              <PackagesEditor
                indexRoom={indexRoom}
                elRoom={elRoom}
                rooms={rooms}
                setRooms={setRooms}
                config={checkboxFacilities}
                objectKey="checkboxFacilities"
              />

              <PackagesEditor
                indexRoom={indexRoom}
                elRoom={elRoom}
                rooms={rooms}
                setRooms={setRooms}
                config={intervalFacilities}
                objectKey="intervalFacilities"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const ArayEditor = ({ indexRoom, elRoom, rooms, setRooms, fieldKey }) => {
  const [localPhotoUrl, setLocalPhotoUrl] = useState("");

  const handlePhotoFieldUpdate = (e) => {
    setLocalPhotoUrl(e.target.value);
  };
  const handleAddPhoto = (roomIndex) => {
    if (localPhotoUrl !== "") {
      let extractRoom = rooms[roomIndex];
      extractRoom[fieldKey].push(localPhotoUrl);
      setRooms((prev) => {
        let copy = [...prev];
        copy[roomIndex] = extractRoom;
        return copy;
      });
    } else {
      window.alert("Please complete photo field first!");
    }
  };
  const handleDeletePhoto = (roomIndex, photoIndex) => {
    // extract specific room
    let extractRoom = rooms[roomIndex];

    // extract photos from room
    let extractPhotos = extractRoom[fieldKey];

    // update photos
    extractPhotos.splice(photoIndex, 1);

    // Insert back update photos into room
    extractRoom[fieldKey] = [...extractPhotos];

    // Insert back room into rooms
    setRooms((prev) => {
      let copy = [...prev];
      copy[roomIndex] = { ...extractRoom };
      return copy;
    });
  };
  const [localColor, setLocalColor] = useState(getRandomColor());

  return (
    <div
      style={{
        backgroundColor: localColor,
        marginBottom: "20px",
      }}
    >
      <br />
      <TextField
        id="outlined-basic"
        label={`add ${fieldKey}`}
        name={`add ${fieldKey}`}
        value={localPhotoUrl}
        onChange={handlePhotoFieldUpdate}
      />
      <button
        onClick={() => {
          handleAddPhoto(indexRoom);
        }}
      >
        Add photo
      </button>
      {elRoom[fieldKey].map((elPhotos, photoIndex) => {
        return (
          <p>
            {elPhotos}-
            <button
              onClick={() => {
                handleDeletePhoto(indexRoom, photoIndex);
              }}
            >
              del
            </button>
          </p>
        );
      })}
    </div>
  );
};

export const PackagesEditor = ({ indexRoom, elRoom, rooms, setRooms, config, objectKey }) => {
  const initState = (source) => {
    let res = {};
    Object.keys(source).forEach((elKey) => {
      if (config[elKey] == "string") {
        //string
        res[elKey] = "";
      } else {
        //number
        res[elKey] = 0;
      }
    });
    return res;
  };

  const [localState, setLocalState] = useState(initState(config));

  const handleStateUpdate = (e) => {
    setLocalState((prev) => {
      let copy = { ...prev };
      if (config[e.target.name] == "string") {
        //string
        copy[e.target.name] = e.target.value;
      } else {
        //number
        copy[e.target.name] = Number(e.target.value);
      }
      return copy;
    });
  };

  const handlePackAdd = () => {
    // Check local state field to be not empty
    let prevPacks = elRoom[objectKey];
    prevPacks.push(localState);

    setRooms((prev) => {
      let copy = [...prev];
      copy[indexRoom][objectKey] = [...prevPacks];
      return copy;
    });

    setLocalState(initState(config));
  };

  const handlePackDel = (indexPack) => {
    let prevPacks = elRoom[objectKey];
    prevPacks.splice(indexPack, 1);
    setRooms((prev) => {
      let copy = [...prev];
      copy[indexRoom][objectKey] = prevPacks;
      return copy;
    });
  };

  const [localColor, setLocalColor] = useState(getRandomColor());
  return (
    <div
      style={{
        backgroundColor: localColor,
        marginBottom: "20px",
      }}
    >
      <h5>-={objectKey}=-</h5>
      <br />
      <button onClick={handlePackAdd}>Add new {objectKey}</button>
      {Object.keys(localState).map((el) => {
        return (
          <TextField
            id="outlined-basic"
            label={el}
            name={el}
            type={"text"}
            value={localState[el]}
            onChange={handleStateUpdate}
          />
        );
      })}
      {elRoom[objectKey].map((packElem, packIndex) => {
        return (
          <div>
            <br />
            <span>------</span>
            <button onClick={() => handlePackDel(packIndex)}>Del this {objectKey}</button>
            {Object.keys(packElem).map((packDeepEl) => {
              return (
                <>
                  <span>
                    {packDeepEl}={packElem[packDeepEl]}
                  </span>
                  &nbsp; &nbsp;
                </>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

import axios from "axios";
import "./Style/Terms.css";
import { SERVER_ADDR } from "../../config";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TopBar from "../../components/Landing/TopBar";
import Footer from "../../components/Landing/Footer";

export default function Terms() {
  const location = useLocation();
  const [hotelId, setHotelId] = useState();
  const [hotelData, setHotelData] = useState();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const hotel = queryParams.get("hotel");
    if (hotel == null || hotel == undefined) {
      window.alert("Wrong hotel id!");
      window.location.href = "/fallback";
    } else {
      setHotelId(hotel);
    }
  }, []);

  useEffect(() => {
    if (hotelId !== null && hotelId !== undefined) {
      fetchHotel(hotelId);
    }
  }, [hotelId]);

  const fetchHotel = async (hotelId) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(`${SERVER_ADDR}/api/property/${hotelId}`, config);
      setHotelData(data);
    } catch (err) {
      console.log("can't fetch this hotel id:", err);
    }
  };

  const createMarkup = (termsSource) => {
    return { __html: termsSource };
  };

  return (
    <>
      {hotelData == undefined ? (
        <p>Loading...</p>
      ) : (
        <div className="terms-container">
          <TopBar hotelData={hotelData} />
          <div className="terms-container-core">
            {hotelData == undefined ? null : (
              <div dangerouslySetInnerHTML={createMarkup(hotelData.setup.terms)} />
            )}
          </div>
          <Footer hotelData={hotelData} />
        </div>
      )}
    </>
  );
}

import { useEffect } from "react";
import CircularLoading from "../../atoms/CircularLoading/CircularLoading";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SERVER_ADDR } from "../../config";

export default function LandingWrapper() {
  const navigate = useNavigate();

  const checkToken = async () => {
    const myToken = Cookies.get("jwt");
    if (myToken === undefined) {
      navigate("/login");
      return;
    }

    try {
      const { data } = await axios.get(`${SERVER_ADDR}/api/token`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
        },
      });

      let role = data.user.role;
      if (role === "admin") {
        navigate("/admin");
      } else if (role == "sudo") {
        navigate("/sudo");
      }
    } catch (err) {
      navigate("/login");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const LoadingWidget = () => {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <CircularLoading />
      </div>
    );
  };
  return (
    <LoadingWidget/>
  );
}

import axios from "axios";
import "./Style/History.css";
import { SERVER_ADDR } from "../../config";
import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DateRangeSelectorAdmin from "../../components/Dashboard/DateRangeSelectorAdmin";
import { formatDateToYYYYMMDDString } from "../../utils/functions";

export default function History({ user }) {

  const [localDateRange, setLocalDateRange] = useState(initDates);
  const [historyData, setHistoryData] = useState([]);
  
  const fetchHistoryData = async (managedHotelId, startDate, endDate) => {
    try {
      const startDateStr = formatDateToYYYYMMDDString(startDate);
      const endDateStr = formatDateToYYYYMMDDString(endDate);

      let historyResp = await axios.get(`${SERVER_ADDR}/api/events?hotelId=${managedHotelId}&startDate=${startDateStr}&endDate=${endDateStr}`);
      setHistoryData(historyResp.data);
    } catch (err) {
      window.alert("Nu pot prelua istoric!");
    }
  };

  useEffect(() => {
    const defaultDateRange = initDates();
    fetchHistoryData(user["managedHotel"], defaultDateRange[0], defaultDateRange[1]);
  }, [user]);

  const handleRefresh = ()=>{
    fetchHistoryData(user["managedHotel"], localDateRange[0], localDateRange[1])
  }

  return (
    <div className="history-container">
      <div className="history-container-core-select">
       <DateRangeSelectorAdmin 
        localDateRange={localDateRange}
        setLocalDateRange={setLocalDateRange} 
        btnAction={handleRefresh}
      />

      </div>
      <div className="history-container-core-content">
        {historyData.map((el) => {
          return <HistoryItem {...el} />;
        })}
      </div>
    </div>
  );
}

const HistoryItem = (data) => {
  const formatDate = (dateSrc) => {
    let date = new Date(dateSrc);
    return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  };

  const decideStatusIcon = (statusSrc) => {
    const steps_statuses = {
      "În așteptare": "pending.png",
      pending: "pending.png",
      Completat: "completed.png",
      Eșuat: "failed.png",
      "Nu mai este necesar": "obsolete.png",
    };
    return steps_statuses[statusSrc];
  };
  return (
    <div className="history-container-core-content-item">
      <Accordion sx={{ paddingLeft: "20px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ padding: "0px" }}
        >
          <div className="history-container-core-content-item-title">
            <div className="history-container-core-content-item-title-left">
              <img src="/adminDash/bell.png" alt="event" />
            </div>
            <div className="history-container-core-content-item-title-right">
              <h4>{data.name}</h4>
              <h5>{formatDate(data.date)}</h5>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="history-container-core-content-item-content">
            <div className="history-container-core-content-item-desc">
              <img src="/adminDash/credit-card.png" alt="payment" />
              <p>{data.description}</p>
            </div>
            <div className="history-container-core-content-item-sub">
              {data.steps.map((el) => {
                return (
                  <div className="history-container-core-content-item-sub-core">
                    <div className="history-container-core-content-item-sub-core-top">
                      <img
                        src={`/icons/${decideStatusIcon(el.status)}`}
                        alt="history"
                        style={{ height: "20px" }}
                      />
                      <p>{el.name}</p>
                    </div>
                    <div className="history-container-core-content-item-sub-core-bot">
                      <p>{el.comment}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

function initDates() {
  const offset = 7;
  const currentDate = new Date();
  
  const pastDate = new Date();
  pastDate.setDate(currentDate.getDate() - offset);
  
  const futureDate = new Date();
  futureDate.setDate(currentDate.getDate() + offset);
  
  return [pastDate, futureDate];
}
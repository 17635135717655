import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axios from "axios";
import Cookies from "js-cookie";

import "../Style/AddProperty.css";

import { setup, landingCss, srlDetails, details, simulateAPISource } from "./config.js";

import { KeyValueEditor, ArrayTypeEditor, ComplexRoomEditor } from "./Editors.js";
import { SERVER_ADDR } from "../../../config.js";

export default function AddProperty() {
  // Tabs
  const [tabValue, setTabValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Data bucket
  const [data, setData] = useState({});
  const [photos, setPhotos] = useState([]);
  const [rooms, setRooms] = useState([]);

  const [loading, setLoading] = useState(true);

  const initStateFromTemplate = () => {
    // Extract data hotel data from API
    let apiData = { ...simulateAPISource };
    delete apiData["photos"];
    delete apiData["rooms"];

    let apiPhotos = [...simulateAPISource["photos"]];
    let apiRooms = [...simulateAPISource["rooms"]];

    // Introduce hotel data into editor
    setData(apiData);
    setPhotos(apiPhotos);
    setRooms(apiRooms);

    setTimeout(() => {
      setLoading(false);
    }, 100);
  };
  useEffect(() => {
    initStateFromTemplate();
  }, []);

  // Create new hotel
  const [needsUpdate, setNeedsUpdate] = useState(false);

  const handleCreateHotel = async () => {
    const finalHotelObj = { ...data };
    finalHotelObj["photos"] = [...photos];
    finalHotelObj["rooms"] = [...rooms];

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt")}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${SERVER_ADDR}/api/property/`,
        { hotel: finalHotelObj },
        config
      );
      window.alert("property added!");
      window.location.reload();
    } catch (err) {
      console.log("can't add property:", err);
    }
  };

  return (
    <>
      {loading == true ? (
        <p>Loading</p>
      ) : (
        <div className="add-property-container">
          <button className="sudo-btn-action" onClick={handleCreateHotel}>
            Add new hotel
          </button>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Hotel Setup" value="1" />
                <Tab label="Landing Css" value="2" />
                <Tab label="Srl Details" value="3" />
                <Tab label="Hotel Details" value="4" />
                <Tab label="Photos" value="5" />
                <Tab label="Rooms" value="6" />
              </TabList>
            </Box>

            {/* Type 1 editor */}
            <TabPanel value="1">
              <KeyValueEditor objSource={setup} dataKey={"setup"} data={data} setData={setData} />
            </TabPanel>
            <TabPanel value="2">
              <KeyValueEditor
                objSource={landingCss}
                dataKey={"landingCss"}
                data={data}
                setData={setData}
              />
            </TabPanel>
            <TabPanel value="3">
              <KeyValueEditor
                objSource={srlDetails}
                dataKey={"srlDetails"}
                data={data}
                setData={setData}
              />
            </TabPanel>
            <TabPanel value="4">
              <KeyValueEditor
                objSource={details}
                dataKey={"details"}
                data={data}
                setData={setData}
              />
            </TabPanel>

            {/* Type 2 editor */}
            <TabPanel value="5">
              <ArrayTypeEditor photos={photos} setPhotos={setPhotos} />
            </TabPanel>

            {/* Type 3 editor */}
            <TabPanel value="6">
              <ComplexRoomEditor rooms={rooms} setRooms={setRooms} />
            </TabPanel>
          </TabContext>
        </div>
      )}
    </>
  );
}

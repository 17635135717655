// import { CalendarDate } from "@internationalized/date";
import axios from "axios";
import Cookies from "js-cookie";

export const verificaDisponibilitate = (sourceDates) => {
  const keys = Object.keys(sourceDates);

  if (keys.length === 0) {
    return false;
  }

  return keys.some((el) => sourceDates[el] === 0);
};

export const dateToAPIFormat = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const formatDate = (source) => {
  const day = source.day.toString().padStart(2, "0");
  const month = source.month.toString().padStart(2, "0");
  const year = source.year;

  return `${year}-${month}-${day}`;
};

export const extractUserData = async () => {
  try {
    const { data } = await axios.post("http://localhost:3002/user/get-user", {
      jwt: Cookies.get("jwt"),
    });
    return data;
  } catch {
    console.log("Eroare in extract user data");
    return null;
  }
};

export function getRandomColor() {
  // Generăm valori aleatoare mai mari pentru a obține culori mai deschise
  const red = Math.floor(Math.random() * 128) + 128; // Interval: [128, 255]
  const green = Math.floor(Math.random() * 128) + 128; // Interval: [128, 255]
  const blue = Math.floor(Math.random() * 128) + 128; // Interval: [128, 255]

  // Transformăm valorile în format hexadecimal și le concatenăm
  const hexRed = red.toString(16).padStart(2, "0");
  const hexGreen = green.toString(16).padStart(2, "0");
  const hexBlue = blue.toString(16).padStart(2, "0");

  // Construim culoarea finală în format hex
  const hexColor = `#${hexRed}${hexGreen}${hexBlue}`;

  return hexColor;
}

// Used in landing page to parse string to date and date to string
export function formatDateToYYYYMMDDString(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adaugă zero în față dacă este necesar
  const day = date.getDate().toString().padStart(2, "0"); // Adaugă zero în față dacă este necesar

  return `${year}-${month}-${day}`;
}

export const parseStringYYYYMMDDtoDate = (dateString) => {
  var dateComponents = dateString.split("-");
  var year = parseInt(dateComponents[0], 10);
  var month = parseInt(dateComponents[1], 10) - 1; // lunile sunt indexate de la 0 în JavaScript
  var day = parseInt(dateComponents[2], 10);

  var convertedDate = new Date(year, month, day);

  return convertedDate;
};

export function setQueryParam(key, value) {
  // Obține parametrii actuali din query string
  const queryParams = new URLSearchParams(window.location.search);

  // Setează sau actualizează valoarea pentru cheia specificată
  queryParams.set(key, value);

  // Actualizează URL-ul fără a reîmprospăta pagina
  const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
  window.history.pushState({ path: newUrl }, "", newUrl);
}

export function generateBetweenDates(dataInceput, dataSfarsit) {
  // Primese 2 obiecte de tip date si genereaza toate datele intermediare dintre acestea
  const dateIntermediare = [];
  const dataCurenta = new Date(dataInceput);

  while (dataCurenta <= dataSfarsit) {
    dateIntermediare.push(new Date(dataCurenta));
    dataCurenta.setDate(dataCurenta.getDate() + 1);
  }

  return dateIntermediare;
}

export function extractDateRangeNumber(dateRangeParam) {
  let datesBetween = generateBetweenDates(dateRangeParam[0], dateRangeParam[1]);
  return datesBetween.length;
}

export function openLinkInNewTab(link) {
  const newTab = window.open(link, "_blank");
  newTab.focus();
}

export const extractPriceFromCheckout = (checkoutSource, ratesSource, dateRangeSource) => {
  // Genereaza datele calendaristice intermediare (vor fi folosite pentru a lua pretul pe zi)
  let betweenDates = generateBetweenDates(dateRangeSource[0], dateRangeSource[1]);
  betweenDates = betweenDates.map((el) => formatDateToYYYYMMDDString(el)); //2023-12-24

  let numberOfDays = betweenDates.length;

  let totalRooms = 0;
  let totalExtraUsers = 0;
  let totalCheckboxes = 0;
  let totalInterval = 0;

  let collectItems = [];

  checkoutSource.forEach((checkoutEl) => {
    // extract rooms
    let thisRoomRates = ratesSource[checkoutEl.ratesId];

    let currentRoomAcumulator = checkoutEl["priceEntryPoint"];
    totalRooms += checkoutEl["priceEntryPoint"];

    // extract rooms - (stripe elelement)
    collectItems.push({
      price: currentRoomAcumulator * 100, //group all days togheter, bcs different days can have differnt price
      quantity: 1,
      description: checkoutEl.roomDetailsForCheckout.name,
    });

    //extract extra users
    let currentUsers = checkoutEl.extraUsers;
    totalExtraUsers =
      currentUsers.selectedAdults * currentUsers.extraAdultPrice * numberOfDays +
      currentUsers.selectedChilds * currentUsers.extraChildPrice * numberOfDays;

    //extract extra users - stripe element
    if (currentUsers.selectedAdults > 0) {
      collectItems.push({
        price: currentUsers.extraAdultPrice * 100,
        quantity: currentUsers.selectedAdults,
        description: "Extra adulti",
      });
    }

    if (currentUsers.selectedChilds > 0) {
      collectItems.push({
        price: currentUsers.extraChildPrice * 100,
        quantity: currentUsers.selectedChilds,
        description: "Extra copii",
      });
    }

    //extract checkboxes
    let currentCheckBoxes = checkoutEl.checkboxFacilities;
    currentCheckBoxes.forEach((checkboxEl) => {
      if (checkboxEl.selected == true) {
        totalCheckboxes += checkboxEl.price;

        //extract checkboxes - stripe format
        collectItems.push({
          price: checkboxEl.price * 100,
          quantity: 1,
          description: checkboxEl.name,
        });
      }
    });

    //extract intervals
    let currentIntervals = checkoutEl.intervalFacilities;
    currentIntervals.forEach((intervalEl) => {
      if (intervalEl.selectedValue > 0) {
        totalInterval += intervalEl.price * intervalEl.selectedValue;

        //extract intervals - stripe format
        collectItems.push({
          price: intervalEl.price * 100,
          quantity: intervalEl.selectedValue,
          description: intervalEl.name,
        });
      }
    });
  });

  //Extract checkboxes
  return {
    total: totalRooms + totalExtraUsers + totalCheckboxes + totalInterval,
    details: {
      totalRooms,
      totalExtraUsers,
      totalCheckboxes,
      totalInterval,
    },
    collectItems: collectItems, //stripe use
  };
};

export const extractRoomPricesPerDay = (checkoutSource, ratesSource, dateRangeSource) => {
  let betweenDates = generateBetweenDates(dateRangeSource[0], dateRangeSource[1]);
  betweenDates = betweenDates.map((el) => formatDateToYYYYMMDDString(el)); //2023-12-24

  let roomsIds = [];
  checkoutSource.forEach((checkoutItem) => {
    let currentRoomIds = {
      channexRoomId: checkoutItem["channexRoomId"],
      ratesId: checkoutItem["ratesId"],
      selectedPackage: checkoutItem["selectedPackage"],
    };
    roomsIds.push(currentRoomIds);
  });

  let roomsIdsWithPrices = [];
  roomsIds.forEach((checkoutRoomItem) => {
    let currentRoomPrices = {};
    betweenDates.forEach((dayKey) => {
      currentRoomPrices[dayKey] = ratesSource[checkoutRoomItem.ratesId][dayKey];
    });

    let roomIdWithPrice = {
      ...checkoutRoomItem,
      currentRoomPrices: currentRoomPrices,
    };
    roomsIdsWithPrices.push(roomIdWithPrice);
  });
  return roomsIdsWithPrices;
};

export const extractPriceWithFacilitiesFromCheckout = (dataSrc) => {
  let totalExtraUsers = 0;
  let totalCheckboxes = 0;
  let totalInterval = 0;
  let collectItems = [];

  let currentUsers = dataSrc.extraUsers;
  totalExtraUsers =
    currentUsers.selectedAdults * currentUsers.extraAdultPrice +
    currentUsers.selectedChilds * currentUsers.extraChildPrice;

  //extract extra users - stripe element
  if (currentUsers.selectedAdults > 0) {
    collectItems.push({
      price: currentUsers.extraAdultPrice * 100,
      quantity: currentUsers.selectedAdults,
      description: "Extra adulti",
    });
  }

  if (currentUsers.selectedChilds > 0) {
    collectItems.push({
      price: currentUsers.extraChildPrice * 100,
      quantity: currentUsers.selectedChilds,
      description: "Extra copii",
    });
  }

  //extract checkboxes
  let currentCheckBoxes = dataSrc.checkboxFacilities;
  currentCheckBoxes.forEach((checkboxEl) => {
    if (checkboxEl.selected === true) {
      totalCheckboxes += checkboxEl.price;

      //extract checkboxes - stripe format
      collectItems.push({
        price: checkboxEl.price * 100,
        quantity: 1,
        description: checkboxEl.name,
      });
    }
  });

  //extract intervals
  let currentIntervals = dataSrc.intervalFacilities;
  currentIntervals.forEach((intervalEl) => {
    if (intervalEl.selectedValue > 0) {
      totalInterval += intervalEl.price * intervalEl.selectedValue;

      //extract intervals - stripe format
      collectItems.push({
        price: intervalEl.price * 100,
        quantity: intervalEl.selectedValue,
        description: intervalEl.name,
      });
    }
  });

  return {
    total: dataSrc["priceEntryPoint"] + totalExtraUsers + totalCheckboxes + totalInterval,
    items: collectItems,
  };
};

import { useEffect, useState } from "react";
import LeftBar from "./LeftBar";
import Cookies from "js-cookie";
import "./Style/DashboardTemplate.css";
import { useNavigate } from "react-router-dom";
import { appMap } from "../../user/user-acces";
import TopBarDash from "../../components/Dashboard/TopBarDash";

const DashboardTemplate = ({ availableTo, Target, user, page }) => {
  const navigate = useNavigate();

  const [currentPageName, setCurrentPageName] = useState("Loading...");

  useEffect(() => {
    let extractedPageName = "";
    appMap[availableTo].forEach((el) => {
      if (`/${page}` === el["route"]) {
        extractedPageName = el["name"];
      }
    });
    setCurrentPageName(extractedPageName);
  }, [Target]);

  const logoutHandler = () => {
    Cookies.remove("jwt");
    navigate("/login");
  };

  return (
    <div className="dashboard-container">
      <LeftBar mode={availableTo} page={page} />
      <div className="dashboard-container-core">
        <TopBarDash currentPageName={currentPageName} user={user} logoutHandler={logoutHandler} />
        <div className="dashboard-container-core-content">
          <Target user={user} />
          {/* {availableTo === "admin" ? (
            <div
              className={`dashboard-container-core-content-history ${
                historyOpen ? "dashboard-container-core-content-history-closed" : ""
              }`}
            >
              <div className="dashboard-container-core-content-history-header">
                <p>Istoric evenimente</p>
              </div>
              <div className="dashboard-container-core-content-history-items">
                {historyData.map((el) => {
                  return <HistoryItem {...el} />;
                })}
              </div>
            </div>
          ) : null} */}
        </div>
      </div>
    </div>
  );
};

export default DashboardTemplate;

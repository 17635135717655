import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { green } from '@mui/material/colors';
import { styled } from '@mui/system';


const GreenCheckbox = styled(Checkbox)({
    color: '#D5BC78', 
    '&.Mui-checked': {
      color: '#D5BC78', 
    },
});

const CustomCheckbox = ({ checked, onChange }) => {
  return (
    <GreenCheckbox
        sx={{margin: 0}}
        checked={checked}
        onChange={onChange}
        inputProps={{ 'aria-label': 'custom checkbox' }}
    />
  );
};

export default CustomCheckbox;

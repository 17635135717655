import React, { useEffect } from "react";
import "./Style/Checkout.css";
import CheckoutItem from "../../atoms/CheckoutItem/CheckoutItem";
import Button from "../../atoms/Button/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CheckoutModal from "./CheckoutModal";
import { extractDateRangeNumber } from "../../utils/functions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};

export default function Checkout({
  checkout,
  hotelData,
  hotelId,
  dateRange,
  setCheckout,
  deleteFromChekout,
  totalPriceCheckout,
  roomsPricesPerDay,
  rates,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formatDateRangeForCheckInOut = (dateRangeSrc) => {
    let formatCheckoutDate = (dateObj) => {
      let monthsName = [
        "Ianuarie",
        "Februarie",
        "Martie",
        "Aprilie",
        "Mai",
        "Iunie",
        "Iulie",
        "August",
        "Septembrie",
        "Octombrie",
        "Noiembrie",
        "Decembrie",
      ];
      let dayOfTheMonth = dateObj.getDate();
      let monthIndex = dateObj.getMonth();
      return `${dayOfTheMonth} ${monthsName[monthIndex]}`;
    };
    return [formatCheckoutDate(dateRangeSrc[0]), formatCheckoutDate(dateRangeSrc[1])];
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CheckoutModal
            exitHandle={handleClose}
            checkout={checkout}
            hotelData={hotelData}
            hotelId={hotelId}
            dateRange={dateRange}
            rates={rates}
            roomsPricesPerDay={roomsPricesPerDay}
          />
        </Box>
      </Modal>
      <div className="checkout-container">
        <div className="checkout-container-header">
          <p>Rezervarea ta</p>
        </div>
        {checkout.length == 0 ? (
          <div className="checkout-container-empty">
            <img src="/icons/mountain.png" />
            <p>Te rugăm să selectezi o cameră pentru rezervare</p>
          </div>
        ) : (
          <>
            <div className="checkout-container-items">
              {checkout.map((checkEl, index) => {
                return (
                  <CheckoutItem
                    key={index}
                    index={index}
                    data={checkEl}
                    deleteFromChekout={deleteFromChekout}
                    setCheckout={setCheckout}
                  />
                );
              })}
            </div>
            <div className="checkout-container-end">
              <div className="checkout-container-end-left">
                <div className="checkout-container-end-left-icon">
                  <img src="/icons/checkIn.png" />
                  <p>
                    Check in <br></br>(pana la {hotelData.setup.checkInHour})
                    <br />
                    {formatDateRangeForCheckInOut(dateRange)[0]}
                  </p>
                </div>

                <div className="checkout-container-end-left-center">
                  <img src="icons/right-arrow.png" />
                </div>

                <div className="checkout-container-end-left-icon">
                  <img src="/icons/checkOut.png" />
                  <p>
                    Check out <br></br>(pana la {hotelData.setup.checkOutHour})
                    <br />
                    {formatDateRangeForCheckInOut(dateRange)[1]}
                  </p>
                </div>
              </div>

              <div className="checkout-container-end-right">
                <div className="checkout-container-end-right-top">
                  <p>
                    Total{" ("}
                    {extractDateRangeNumber(dateRange) == 1
                      ? "o noapte"
                      : `${extractDateRangeNumber(dateRange)} nopti`}
                    {")"}:
                  </p>
                  {totalPriceCheckout !== null ? <span>{totalPriceCheckout.total} RON</span> : null}
                  <span></span>
                </div>
                <div className="checkout-container-end-right-bottom">
                  <Button text="Finalizeaza" onClick={handleOpen} icon={undefined} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
